import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import {GlobalServices} from '../_services/index';

@Component({
  selector: 'app-amministrazione-utenti',
  templateUrl: './amministrazione-utenti.component.html',
  styleUrls: ['./amministrazione-utenti.component.css']
})
export class AmministrazioneUtentiComponent implements OnInit {


  constructor( private _changeDetectorRef: ChangeDetectorRef) { }

  ngOnInit() {
    this._changeDetectorRef.detectChanges();
  }

}
