import { Component, Inject, OnInit } from '@angular/core';
import {GlobalServices} from '../_services';
import {DialogData, Note} from '../_models';

import {  Router,ActivatedRoute } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-note-direttore',
  templateUrl: './note-direttore.component.html',
  styleUrls: ['./note-direttore.component.css']
})
export class NoteDirettoreComponent implements OnInit {

  tipi;
  nota= new Note();

  constructor( private service: GlobalServices,private route: ActivatedRoute, private ruoter: Router,public dialogRef: MatDialogRef<NoteDirettoreComponent>,
    @Inject(MAT_DIALOG_DATA) public Ddata: DialogData,) {
      dialogRef.disableClose = true;
     }

  ngOnInit() {
    this.service.get_function(this.service.apiGetUtenti+'/tipo').subscribe(
      data=>{
        this.tipi=data
        this.nota.tipo='0';
        if(this.Ddata.id){
            this.nota.id = this.Ddata.id;
            if(this.nota.id) this.CaricaNota();
        };
      });
  }

  CaricaNota(){
    this.service.get_function(this.service.apiGetNoteDirettore+this.nota.id).subscribe(data=>this.nota=data[0]);
  }

  Salva(){
    this.service.post_function(JSON.stringify(this.nota),this.service.apiUpdateNoteDirettore).subscribe(
      data=>this.dialogRef.close()
    )
  }

  Chiudi(){
    this.dialogRef.close();
  }
}
