import { Component, OnInit } from '@angular/core';
import {GlobalServices} from '../_services';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-timbri',
  templateUrl: './timbri.component.html',
  styleUrls: ['./timbri.component.css']
})
export class TimbriComponent implements OnInit {

  ingresso= new Ip_Timbri();
  uscita: boolean;
  username: string;
  esitoSave:string;

  constructor(private service: GlobalServices,private notifiche_bottom: MatSnackBar) { }

  ngOnInit() {
    this.username=this.service.get_username();
    this.Verifica();
  }

  Verifica(){
    this.service.get_function(this.service.apiGetIngresso+'/'+this.username).subscribe(
      ingresso=>{
        this.ingresso.ingresso=ingresso;
        if(ingresso!='00:00:00') this.uscita=true;
        else this.uscita=false;
      }
    );
  }

  Timbra(){
    this.service.post_function(JSON.stringify(this.ingresso),this.service.apiTimbra+this.username).subscribe(
      status => {
        var messaggio='';
        this.esitoSave="Operazione effettuata correttamente";
        this.notifiche_bottom.open(this.esitoSave, 'OK', { duration: 2500});
        this.Verifica();
      }
    );
  }

}

export class Ip_Timbri{
  ingresso: string;
  ip: string;
}
