export class Plantari{
    id;
    id_paziente;
    nome;
    cognome;
    data_nascita;
    cognome_nome;
    spedito;
    telefono;
    scarpa;
    acconto;
    data_richiesta;
    data_spedizione;
}