export class Pazienti{
    idscheda: number;
    nome: string;
    cognome: string;
    data_nascita: string;
    cognome_nome:string;
}

export class Pazienti_schede{
    idscheda: number;
    nome: string;
    cognome: string;
    data_nascita: Date;

    constructor(){this.data_nascita=new Date();}
}

export class Pazienti_ordine{
    id;
    idscheda;
    nome;
    cognome;
    data_nascita;
    tipo;
    eseguito;
    ora_accesso;
}

export class Pazienti_certificato{
    nome;
    data_nascita;
    luogo_nascita;
    ore;
    prestazione;
    data;
    accompagnatore;
    tipo_documento;
    nr_documento;
}

export class Lettere{
    h1;
    h2;
    h3;
    h4;
    corpo;
    destinatario;
}