import { Component, OnInit } from '@angular/core';
import {Pazienti, Pazienti_schede} from '../../_models';
import {GlobalServices} from '../../_services';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-paziente',
  templateUrl: './paziente.component.html',
  styleUrls: ['./paziente.component.css']
})
export class PazienteComponent implements OnInit {

  paziente= new Pazienti_schede();
  titolo: string;

  constructor(private service: GlobalServices, private route: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    if(this.paziente.idscheda) this.titolo="Modifica Utente";
    else this.titolo="Nuovo Utente";
    this.route.params.forEach(params => {
      this.paziente.idscheda=params['id'];
      if( this.paziente.idscheda) this.CaricaPaziente();
    });
  }

  CaricaPaziente(){
    this.service.get_function(this.service.apiGetPazienti+this.paziente.idscheda).subscribe(data=>this.paziente=data[0]);
  }

  Save(){
    let tipo=typeof this.paziente.data_nascita;
    if(tipo !='string') this.paziente.data_nascita.setHours(2);
    this.service.post_function(JSON.stringify(this.paziente),this.service.apiUpdatePazienti).subscribe(
      data=>{if(data) this.router.navigate(['archivio/visite',data])}
    )
  }

}
