import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home';
import { LoginComponent } from './login';
import {DashboardComponent} from './dashboard/dashboard.component';
import { AuthGuard } from './_helpers';
import { ArchivioPazientiComponent } from './archivio-pazienti/archivio-pazienti.component';
import { ElencoPazientiComponent } from './archivio-pazienti/elenco-pazienti/elenco-pazienti.component';
import { PazienteComponent } from './archivio-pazienti/paziente/paziente.component';
import { VisiteComponent } from './archivio-pazienti/visite/visite.component';
import { PendingChangesGuard } from './_guards/deactivate';
import { MatriciComponent } from './matrici/matrici.component';
import { HolterComponent } from './holter/holter.component';
import { PlantariComponent } from './plantari/plantari.component';
import { ImpostazioniComponent } from './impostazioni/impostazioni.component';
import { AmministrazioneUtentiComponent } from './amministrazione-utenti/amministrazione-utenti.component';
import { ElencoUtentiComponent } from './amministrazione-utenti/elenco-utenti/elenco-utenti.component';
import { UtenteComponent } from './amministrazione-utenti/utente/utente.component';
import { StatisticheComponent } from './statistiche/statistiche.component';
import { RiepilogoPresenzeDirettoreComponent } from './riepilogo-presenze-direttore/riepilogo-presenze-direttore.component';
import { TimbriComponent } from './timbri/timbri.component';
import { SchedaComponent } from './scheda/scheda.component';
import { CambioPasswordComponent } from './cambio-password/cambio-password.component';
import { PolisonnografiaComponent } from './polisonnografia/polisonnografia.component';

const routes: Routes = [
    { 
        path: '', 
        component: HomeComponent, 
        canActivate: [AuthGuard],
        data: { access_level: [1,2,3,4,5,6,7,8,9,10,11] } ,
        children:[
            {
                path:'',
                component: DashboardComponent,
                canActivate: [AuthGuard],
                data:{access_level: [1,2,3,4,5,6,7,8,9,10,11]}
            },
            {
                component: ArchivioPazientiComponent,
                path: 'archivio',
                canActivate: [AuthGuard],
                data: { access_level: [1,2,3,6,7,8,9,10] },
                children:[
                    {
                        component: ElencoPazientiComponent,
                        path: '',
                        canActivate: [AuthGuard],
                        data: { access_level: [1,2,3,6,7,8,9,10] }
                    },
                    {
                        component: ElencoPazientiComponent,
                        path: ':id',
                        canActivate: [AuthGuard],
                        data: { access_level: [1,2,3,6,7,8,9,10] }
                    },
                    {
                        component: PazienteComponent,
                        path: 'paziente',
                        canActivate: [AuthGuard],
                        data: { access_level: [1,2,6,7,8,9,10] }
                    },
                    {
                        component: PazienteComponent,
                        path: 'paziente/:id',
                        canActivate: [AuthGuard],
                        data: { access_level: [1,2,6,7,8,9,10] }
                    },
                    {
                        component: VisiteComponent,
                        path: 'visite/:id',                        
                        canDeactivate: [PendingChangesGuard],
                        canActivate: [AuthGuard],
                        data: { access_level: [1,2,6,7,8,9,10] }
                    },
                    {
                        component: VisiteComponent,
                        path: 'visite/:id/:branca',                        
                        canDeactivate: [PendingChangesGuard],
                        canActivate: [AuthGuard],
                        data: { access_level: [1,2,6,7,8,9,10] }
                    },
                    {
                        component: VisiteComponent,
                        path: 'visite/:id/:branca/:priorita',                        
                        canDeactivate: [PendingChangesGuard],
                        canActivate: [AuthGuard],
                        data: { access_level: [1,2,6,7,8,9,10] }
                    }
                ]
            },
            {
                component: MatriciComponent,
                path: 'matrici',                       
                canActivate: [AuthGuard],
                data: { access_level: [1,2,6,7,8,9,10] }
            },
            {
                component: HolterComponent,
                path: 'holter',                       
                canActivate: [AuthGuard],
                data: { access_level: [1,2,6,7] }
            },
            {
                component: PlantariComponent,
                path: 'plantari',
                canActivate: [AuthGuard],
                data: { access_level: [1,2] }
            },
            {
                component: PolisonnografiaComponent,
                path: 'polisonnografia',
                canActivate: [AuthGuard],
                data: { access_level: [1,2,11] }
            },
            {
                component: ImpostazioniComponent,
                path: 'impostazioni',               
                canActivate: [AuthGuard],
                data: { access_level: [1] }
            },
            {
                component: TimbriComponent,
                path: 'timbra',
                canActivate: [AuthGuard],
                data: { access_level: [1,2,3,4,5,6,7,8,9,10] }
            },
            {
                component: RiepilogoPresenzeDirettoreComponent,
                path: 'riepilogo-presenze',
                canActivate: [AuthGuard],
                data: { access_level: [1] }
            },
            {
               component: StatisticheComponent,
               path: 'statistiche',
               canActivate: [AuthGuard],
               data: { access_level: [1] }
           },
           {
                component: SchedaComponent,
                path: 'scheda',
                canActivate: [AuthGuard],
                data: { access_level: [1,2] }
            },
            {
                component: SchedaComponent,
                path: 'scheda/:id',
                canActivate: [AuthGuard],
                data: { access_level: [1,2] }
            },
            {
                component: SchedaComponent,
                path: 'scheda/:action/:id',
                canActivate: [AuthGuard],
                data: { access_level: [1,2] }
            },
            {
               component: AmministrazioneUtentiComponent,
               path: 'amministrazione-utenti',
               canActivate: [AuthGuard],
               data: { access_level: [1] },
               children:[
                   {
                       component: ElencoUtentiComponent,
                       path: '',
                       canActivate: [AuthGuard],
                       data: { access_level: [1] }
                   },
                   {
                       component: UtenteComponent,
                       path: 'utente',
                       canActivate: [AuthGuard],
                       data: { access_level: [1] }
                   },
                   {
                       component: UtenteComponent,
                       path: 'utente/:id',
                       canActivate: [AuthGuard],
                       data: { access_level: [1] }
                   }
               ]
           }
        ]
    },
    { 
        path: 'login', 
        component: LoginComponent 
    },
    { 
        path: 'chgpsw', 
        component: CambioPasswordComponent
    },

    // otherwise redirect to home
    { path: '**', redirectTo: '' }
];

export const appRoutingModule = RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy', useHash: true });