import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material';

import { AuthenticationService } from './_services';
import { Utenti } from './_models';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  currentUser: Utenti;

    constructor(
        private router: Router,
        private authenticationService: AuthenticationService,
        private _iconRegistry: MatIconRegistry,
        private _domSanitizer: DomSanitizer
    ) {
        this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
        this._iconRegistry.addSvgIcon('holter',this._domSanitizer.bypassSecurityTrustResourceUrl("../assets/holter.svg"));
        this._iconRegistry.addSvgIcon('orme',this._domSanitizer.bypassSecurityTrustResourceUrl("../assets/orme.svg"));
    }

    logout() {
        this.authenticationService.logout();
        this.router.navigate(['/login']);
    }
}
