export class Scheda{
    id_scheda: number;
    diagnosi:string;
    note_operative: string;
    note:string;
    terapie: Array<Terapia>
  attivo: number;

    constructor(){
        this.terapie=new Array<Terapia>();
    }
}

export class Terapia{
    id_terapia: string;
    terapia:string;
    id_segmento: string;
    segmento:string;
    quantita_prescritta: string;
    quantita_da_eseguire;
    quantita_eseguite;
    eseguite: Array<Terapia_eseguita>;

    constructor(){
        this.eseguite=new Array<Terapia_eseguita>();
    }
}

export class Terapia_eseguita{
    box: string;
    timestamp: string;
    username: string;
}

export class Scheda_Visita{
    id_scheda: number;
    diagnosi:string;
    note_operative: string;
    note:string;
    terapie_asl: Array<Terapia_visita>
    terapie: Array<Terapia_visita>
    altro: string;

    constructor(){
        this.terapie_asl=new Array<Terapia_visita>();
        this.terapie=new Array<Terapia_visita>();
    }
}

export class Terapia_visita{
    id_terapia: string;
    terapia:string;
    id_segmento: string;
    segmento:string;
    quantita_prescritta: string;
    in_scheda: boolean;
}

export class Oggetto_Esegui_terapia{
    segmento: number;
    terapia: number;
    idscheda: number;
    box: string;
  }
  
  export class Oggetto_Esegui_terapia_forzata{
    username: string;
    segmento: number;
    terapia: number;
    idscheda: number;
    box: string;
    timestamp: string;
  }
  
  export class Oggetto_Rimuovi_terapia{
    segmento: number;
    terapia: number;
    idscheda: number;
    timestamp: string;
  }
  
