import { Component, OnInit } from '@angular/core';
import { Form_Presenze } from '../_models';
import { GlobalServices } from '../_services';
import { MatTableDataSource } from '@angular/material';

@Component({
  selector: 'app-statistiche',
  templateUrl: './statistiche.component.html',
  styleUrls: ['./statistiche.component.css']
})
export class StatisticheComponent implements OnInit {

  form_presenza= new Form_Presenze;
  form_p=new Form_Presenze;
  utenti=new Array<any>();
  terapie_eseguite=new Array<any>();
  array_operatori=new Array<any>();
  array_valori=new Array<any>();
  numero_terapie: number;
  array_finale= new Array<any>();
  array_grande= new Array<any>();
  array_stats;
  dataSource;
  dataSource2;
  displayedColumns = ['operatore', 'prestazioni', 'totale', 'media'];
  displayedColumns2 = ['giorno', 'dettaglio'];

  constructor(private service: GlobalServices) { }

  ngOnInit() {
    this.CaricaUtenti();
    this.dataSource = new MatTableDataSource(this.array_valori); 
    this.dataSource2 = new MatTableDataSource(this.array_grande);
  }

  Cerca(){
    this.CaricaTerapie();
  }

  CaricaUtenti(){
    this.service.get_function(this.service.apiGetUsername+'/now').subscribe(
      data=>this.utenti=data
    )
  }

  CaricaTerapie(){
    this.service.post_function(JSON.stringify(this.form_presenza),this.service.apiGetTerapieEseguite).subscribe(
      data=>{
        this.terapie_eseguite=data;
        this.numero_terapie=data.length;
        
        this.service.get_function(this.service.apiGetUsername+'/sttr').subscribe(
          data=>
          {
            this.array_operatori=data;
            this.Statistiche();
          }
        )
      }
    )
  }

  CercaPrestazioni(){
    this.service.post_function(JSON.stringify(this.form_presenza),this.service.apiGetRiepilogoPrestazioni).subscribe(
      data=>{
        this.array_stats= new Array<any>();
        this.array_stats=data;
      }
      );
  }

  Statistiche(){
    this.array_valori=new Array<any>();
    this.array_operatori.forEach(element => {
      this.array_valori.push({'name': element.username,'value':0,'totale':'0', ore: 0});
    });
    this.terapie_eseguite.forEach(terapia=>{
      for(let i=0;i<this.array_valori.length;i++){
        if(this.array_valori[i].name==terapia.username) this.array_valori[i].value++
      }
    });
    this.array_operatori.forEach(user=>{
      this.form_presenza.username=user.username;
      this.service.post_function_bis(JSON.stringify(this.form_presenza),this.service.apiGetRiepilogo).subscribe(
        data=>{
          for(let i=0;i<this.array_valori.length;i++){
            if(this.array_valori[i].name==user.username){
               this.array_valori[i].totale=data['totale'];
               let a=this.array_valori[i].totale.indexOf(':');
               let b=this.array_valori[i].totale.indexOf(':', a+1);
               let ore=parseInt(this.array_valori[i].totale.substring(0,a));
               let minuti=parseInt(this.array_valori[i].totale.substring(a+1,b));
               this.array_valori[i].ore=ore+(minuti/60);
               
            }
          
          }
          this.dataSource = new MatTableDataSource(this.array_valori);  
        }
      )
    });
    let data_i=new Date(this.form_presenza.data_in);
    this.array_grande=new Array<any>();
    while(data_i<=new Date(this.form_presenza.data_fin)){
      let x=data_i.getDate()+'/'+(data_i.getMonth()+1)+'/'+data_i.getFullYear();
      let y=this.array_grande.length;
      this.array_grande[y]=new OBJ2;
      this.array_grande[y].data= x;
      this.array_grande[y].value=new Array<OBJ>();
      this.array_operatori.forEach(user=>{
        this.form_p.username=user.username;
        this.form_p.data_in=data_i;
        this.form_p.data_fin=data_i;
        this.service.post_function_bis(JSON.stringify(this.form_p),this.service.apiGetTerapieEseguiteOperatore).subscribe(
          data=>{
                let obj= new OBJ;
                obj.username=user.username;
                obj.totale=data['totale'];
                obj.terapie=data['data'];
                let c=obj.totale.indexOf(':');
                let d=obj.totale.indexOf(':', c+1);
                let ore2=parseInt(obj.totale.substring(0,c));
                let minuti2=parseInt(obj.totale.substring(c+1,d));
                //console.log(obj.totale+' ore:'+ore2+' minuti:'+minuti2+' numero terapie');
                obj.media=obj.terapie.length/(ore2+(minuti2/60));
                this.array_grande[y].value.push(obj);        
                 }
        )
                
      });
      data_i.setDate(data_i.getDate()+1);
      this.dataSource2=new MatTableDataSource(this.array_grande);
      console.log(this.dataSource2);
    }
  }

}

class OBJ{
  username;
  totale;
  media;
  terapie;
}

class OBJ2{
  data;
  value;
}


