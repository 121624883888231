import { Component, OnInit } from '@angular/core';
import {Scheda, Terapia, Pazienti_schede, Pazienti} from '../_models/index';
import {GlobalServices} from '../_services/index';
import {  Router,ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-scheda',
  templateUrl: './scheda.component.html',
  styleUrls: ['./scheda.component.css']
})
export class SchedaComponent implements OnInit {

  terapies;
  terapie_asl;
  segmenti;
  paziente= new Pazienti_schede;
  pazienti= new Array<Pazienti>();
  scheda= new Scheda;
  esitoSave: string;
  stato_update=true;
  union= new Union();
  params;


  constructor(
    private service: GlobalServices,
    private notifiche_bottom: MatSnackBar,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    
    this.service.get_function(this.service.apiGetPazienti).subscribe(resp=>{
      this.pazienti=resp;    
    });
    this.service.get_function(this.service.apiGetTerapie+'asl/').subscribe(
      data=>this.terapie_asl=data
    )
    this.service.get_function(this.service.apiGetTerapie).subscribe(
      data=>{
        this.terapies=data
        this.service.get_function(this.service.apiGetSegmenti).subscribe(
         segmenti=>{
           this.segmenti=segmenti;
           this.route.params.forEach(params => {
            if(params['action']) 
            {
              this.paziente.idscheda=params['id'];
              if(this.paziente.idscheda) 
              this.service.get_function(this.service.apiGetPazienti+this.paziente.idscheda).subscribe(data=>{
                this.paziente=data[0];
                if(data[0].data_nascita!='0000-00-00') this.paziente.data_nascita= data[0].data_nascita;
              });
            }
            else if(!this.params){
            this.scheda.id_scheda = params['id'];
            if(this.scheda.id_scheda) {
              this.service.get_function(this.service.apiGetPazientifromScheda + this.scheda.id_scheda).subscribe(val=>{
                this.paziente.idscheda=val[0].id_oggetto;              
                this.service.get_function(this.service.apiGetPazienti+this.paziente.idscheda).subscribe(data=>{
                  this.paziente=data[0];
                  if(data[0].data_nascita!='0000-00-00') this.paziente.data_nascita= data[0].data_nascita;
                });
              });
              this.CaricaScheda()
            }
            else this.scheda.terapie= new Array<Terapia>();
          }
            });
         });
        
      });
  }

  CaricaScheda(){
    this.stato_update=false;
    this.service.get_function(this.service.apiGetScheda+this.scheda.id_scheda).subscribe(dati=>
      {this.scheda=dati[0];
        if(!this.scheda.terapie) this.scheda.terapie= new Array<Terapia>();
      });
  } 

  aggiungi_riga_terapia(){
      let temp= new Terapia;
      temp.id_segmento='1';
      temp.id_terapia='1';
      temp.quantita_prescritta='1';
      this.scheda.terapie.push(temp);
  }

  AggiungiNote(valore){
   if(this.scheda.note) this.scheda.note+=valore.value+' - ';
   else this.scheda.note=valore.value+' - ';
  }

  nuova_scheda(){
    this.scheda=new Scheda;
  }

  trackByIndex(index: number, value: Terapia) {
    return index;
  }

  Rimuoviterapia(i){    
    this.service.post_function(JSON.stringify(this.scheda.terapie[i]), this.service.apiRimuoviTerapiaScheda+this.scheda.id_scheda).subscribe(
      status => {
        var messaggio='';
        this.esitoSave="Operazione effettuata correttamente";
        this.notifiche_bottom.open(this.esitoSave, 'OK', { duration: 2500 });
        this.scheda.terapie.splice(i,1);
      }
    );
  }

  SaveForm(){
    if(this.paziente.nome && this.paziente.cognome && this.paziente.data_nascita || this.paziente.idscheda){
      /*if(typeof this.paziente.data_nascita === "string") {}
      else this.paziente.data_nascita.setHours(2);*/
      this.union.paziente=this.paziente;
      this.union.scheda=this.scheda;
      this.service.post_function(JSON.stringify(this.union),this.service.apiUpdateScheda).subscribe(
        status => {
          var messaggio='';
          this.esitoSave="Operazione effettuata correttamente";
          this.notifiche_bottom.open(this.esitoSave, 'OK', { duration: 2500 });
          this.router.navigate(['/archivio']);
        }
      );
    }
    else{
      window.alert("Seleziona un paziente dall'elenco o inserisci tutti i dati del nuovo paziente");
    }
  }


}

export class Union{
  paziente: Pazienti_schede;
  scheda: Scheda;

}

