<mat-card>
  <mat-card-title>
    Aggiungi/Modifica Nota
  </mat-card-title>
  <mat-divider></mat-divider>
  <br/>
  <mat-card-content>
    <span class="titolo">Testo</span><br/>
    <textarea cols="100" [(ngModel)]="nota.nota" name="nota">
    </textarea>
    <br/>
    <br/>
    <span class="titolo">Seleziona chi deve visualizzare la notifica</span><br/>
    <mat-select [(ngModel)]="nota.tipo" name="tipo" >
      <mat-option value=0>
        Tutti
      </mat-option>
      <mat-option *ngFor="let tipo of tipi" [value]="tipo.typeid">
        {{tipo.tipo_utente}}
      </mat-option>
    </mat-select><br/><br/>
    <button mat-button color="primary" (click)="Salva()">Salva e Chiudi</button>
    <button mat-button color="warn" (click)="Chiudi()">Chiudi senza salvare</button>
  </mat-card-content>
</mat-card>
