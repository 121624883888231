import { Component, OnInit } from '@angular/core';
import {Utenti, Tipo, Listino, array_nomi, TipiVisite} from '../../_models';
import {GlobalServices} from '../../_services/index';
import {  Router,ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material';
import { copyCSSStyles } from 'html2canvas/dist/types/dom/document-cloner';

@Component({
  selector: 'app-utente',
  templateUrl: './utente.component.html',
  styleUrls: ['./utente.component.css']
})
export class UtenteComponent implements OnInit {
  
  utente= new Utenti;
  tipi: Tipo[];
  esitoSave: string;
  stato_update=false;
  change_password=true;
  listino= new Listino;
  listino_v2=new Array<TipiVisite>();
  array_nomi;
  

  constructor(
    private service: GlobalServices,
    private notifiche_bottom: MatSnackBar,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    //this.utente.listino_personale= new Listino();
    /*this.service.get_function(this.service.apiGetMeta+'listino/listino/1').subscribe(data=>{
      this.listino=JSON.parse(data[0].meta_value);
      this.utente.listino_personale=JSON.parse(data[0].meta_value);
    });*/
    this.service.get_function(this.service.apiGetTipiVisite).subscribe(data=>{
      this.listino_v2=data;
      this.utente.listino_personale=this.listino_v2;  
      
    });
    this.utente.data_out=new Date('1900-01-01');
    this.service.get_function(this.service.apiGetUtenti+'/tipo').subscribe(
      data=>{
        this.tipi=data
        this.route.params.forEach(params => {
            this.utente.idutente = params['id'];
            if(this.utente.idutente) this.CaricaUtente();
            else this.change_password=false;
        });
      });
  }

  CambiaListino(){
    var l2=this.listino_v2;
    this.utente.listino_personale.forEach(element => {
      var cc;
      l2.forEach(element2 => {
        if(element2.descrizione==element.descrizione) cc=element2.costo;
      });
      this.utente.listino_personale[this.utente.listino_personale.indexOf(element)].costo=Math.floor((cc*this.utente.percentuale/100)*100.00)/100.00;
    });
    this.service.get_function(this.service.apiGetTipiVisite).subscribe(data=>{
      this.listino_v2=data; 
    });
  }

  ChangePSW(){
    this.change_password=!this.change_password;
  }

  CaricaUtente(){
    this.service.get_function(this.service.apiGetUtente+'/'+this.utente.idutente).subscribe(
      data=>
      {
        this.utente.username=data[0].username;
        this.utente.nominativo=data[0].nominativo;
        this.utente.data_in=data[0].data_in;
        if(data[0].data_out!='0000-00-00' || data[0].data_out!='1900-01-01') this.utente.data_out=data[0].data_out;
        this.utente.ruolo=data[0].ruolo;
        this.utente.rivalsa=data[0].rivalsa;
        this.utente.ritenuta=data[0].ritenuta;
        this.utente.email=data[0].email;
        this.utente.percentuale=data[0].percentuale;
        this.utente.remunerazione_oraria=data[0].remunerazione_oraria;
        if(data[0].listino_personale){
          this.utente.listino_personale=new Array<TipiVisite>();
          let dati=JSON.parse(data[0].listino_personale);
          if(dati.length==this.listino_v2.length && !dati.hasOwnProperty("bpe")) this.utente.listino_personale=dati;
          else if(dati.length!=this.listino_v2.length && !dati.hasOwnProperty("bpe")){
            this.utente.listino_personale=this.listino_v2;
            var l2=dati;
            this.utente.listino_personale.forEach(element => {
              var cc=0;
              l2.forEach(element2 => {
                if(element2.descrizione==element.descrizione) cc=element2.costo;
              });
              if(cc) this.utente.listino_personale[this.utente.listino_personale.indexOf(element)].costo=cc;
            });
            this.service.get_function(this.service.apiGetTipiVisite).subscribe(data=>{
              this.listino_v2=data; 
            });
          }
          else this.utente.listino_personale=this.listino_v2;
        }
        this.stato_update=true;
      }
    );

  }

  makeid(j) {
    var text = ""; 
    var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";

  
    for (var i = 0; i < j; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));
  
    this.utente.password=text;
  }

  SaveForm(){
    this.utente.username=this.utente.username.toUpperCase();
    if(this.utente.password)this.utente.password=this.utente.password.toUpperCase();
    this.service.put_function(JSON.stringify(this.utente), this.service.apiUpdateUtenti).subscribe(
	    status => {
        var messaggio='';
        if(!this.utente.idutente) this.esitoSave="Utente inserito nel sistema";
        else this.esitoSave="Utente modificato correttamente";
        this.notifiche_bottom.open(this.esitoSave, 'OK', { duration: 2500});
        this.router.navigate(["amministrazione-utenti"]);
      });
  }

}

