<mat-card>
  <mat-card-title>Elenco Utenti</mat-card-title>
  <mat-card-content class="push-bottom-none">
          
        <table mat-table [dataSource]="elenco_utenti" class="mat-elevation-z8">
            <ng-container matColumnDef="username">
                <th mat-header-cell *matHeaderCellDef> Username </th>
                <td mat-cell *matCellDef="let element"> {{element.username}} </td>
              </ng-container>
            
              <ng-container matColumnDef="tipo_utente">
                <th mat-header-cell *matHeaderCellDef> Ruolo </th>
                <td mat-cell *matCellDef="let element"> {{element.tipo_utente}} </td>
              </ng-container>

              <ng-container matColumnDef="nominativo">
                <th mat-header-cell *matHeaderCellDef> Nominativo Completo </th>
                <td mat-cell *matCellDef="let element"> {{element.nominativo}} </td>
              </ng-container>

              <ng-container matColumnDef="data_in">
                <th mat-header-cell *matHeaderCellDef> Data Inserimento a sistema </th>
                <td mat-cell *matCellDef="let element">  {{element.data_in | date: 'dd/MM/yyyy'}} </td>
              </ng-container>

              <ng-container matColumnDef="data_out">
                <th mat-header-cell *matHeaderCellDef> Data Eliminazione da sistema </th>
                <td mat-cell *matCellDef="let element">  
                    <div *ngIf="element.data_out!='0000-00-00' && element.data_out!='1900-01-01'">{{element.data_out | date: 'dd/MM/yyyy'}}</div>
                    <div *ngIf="element.data_out=='0000-00-00' || element.data_out=='1900-01-01'">n.d.</div>    
                </td>
              </ng-container>
            
              <ng-container matColumnDef="azioni">
                <th mat-header-cell *matHeaderCellDef> Azioni </th>
                <td mat-cell *matCellDef="let element">  <button mat-circle-button (click)="routing(element.idutente)"><mat-icon mat-list-icon>edit</mat-icon></button> </td>
              </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        
  </mat-card-content>
</mat-card>