export class Impostazioni{
    logo;
    denominazione;
    indirizzo;
    telefono;
    accreditamento;
    direttoresanitario;
    piva;
    email;
    sito;
    posizionelogo;
}