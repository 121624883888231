<h1 mat-dialog-title>Paziente: {{data.paziente}}</h1>
<div mat-dialog-content>
  <p>Note già presenti:</p>
  <p><i>{{data.note_vecchie}}</i></p>
  <mat-form-field appearance="fill">
    <mat-label>Aggiungi note</mat-label>
    <input matInput [(ngModel)]="data.nuova_nota">
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Esci</button>
  <button mat-button [mat-dialog-close]="data.nuova_nota" cdkFocusInitial>Inserisci</button>
</div>