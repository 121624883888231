import { Component, OnInit } from '@angular/core';
import { GlobalServices } from '../_services';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material';
import {Impostazioni, Listino} from '../_models';

@Component({
  selector: 'app-impostazioni',
  templateUrl: './impostazioni.component.html',
  styleUrls: ['./impostazioni.component.css']
})
export class ImpostazioniComponent implements OnInit {

  cdcp= new Impostazioni;
  cs= new Impostazioni;
  m2= new Impostazioni;
  listino= new Listino;
  esitoSave;

  constructor(private service: GlobalServices, private ruoter: Router, private notifiche_bottom: MatSnackBar) { }

  ngOnInit() {
    this.service.get_function(this.service.apiGetImpostazioni+'cs').subscribe(data=>this.cs=JSON.parse(data[0].value));
    this.service.get_function(this.service.apiGetImpostazioni+'m2').subscribe(data=>this.m2=JSON.parse(data[0].value));
    this.service.get_function(this.service.apiGetImpostazioni+'cdcp').subscribe(data=>this.cdcp=JSON.parse(data[0].value));
    this.service.get_function(this.service.apiGetMeta+'listino/listino/1').subscribe(data=>this.listino=JSON.parse(data[0].meta_value));
  }

  SalvaCDCP(){
    this.service.post_function(JSON.stringify(this.cdcp),this.service.apiUpdateImpostazioni+'cdcp').subscribe(
      data=>{
            this.esitoSave="Operazione effettuata correttamente";
            this.notifiche_bottom.open(this.esitoSave, 'OK', { duration: 2500});
      });
  }

  SalvaCS(){   
    this.service.post_function(JSON.stringify(this.cs),this.service.apiUpdateImpostazioni+'cs').subscribe(
      data=>{
            this.esitoSave="Operazione effettuata correttamente";
            this.notifiche_bottom.open(this.esitoSave, 'OK', { duration: 2500 });
      });
  }

  SalvaM2(){
    this.service.post_function(JSON.stringify(this.m2),this.service.apiUpdateImpostazioni+'m2').subscribe(
      data=>{
            this.esitoSave="Operazione effettuata correttamente";
            this.notifiche_bottom.open(this.esitoSave, 'OK', { duration: 2500});
      });
  }
  SalvaListino(){
    this.service.post_function(JSON.stringify(this.listino),this.service.apiPostMeta+'listino/listino/1').subscribe(
      data=>{
            this.esitoSave="Operazione effettuata correttamente";
            this.notifiche_bottom.open(this.esitoSave, 'OK', { duration: 2500});
      });
  }


}

