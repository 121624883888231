    <div flexLayout="row" *ngIf="access_level==3">
        <mat-card>
            <mat-card-title>INGRESSO/USCITA</mat-card-title>
            <mat-card-content>
                <div *ngIf="!uscita && access_level==3 ">NON HAI TIMBRATO L'INGRESSO PER QUESTA SESSIONE DI LAVORO O HAI GIA' TIMBRATO L'USCITA <button mat-button color="primary" (click)="Timbra()">TIMBRA INGRESSO</button></div><!-- - HH:mm:ss' :'+0100'-->
                <div *ngIf="uscita && access_level==3 ">Hai timbrato l'ingresso alle ore: <span style="font-weight: 600; font-size: 150%">{{ingresso.ingresso}}</span><button mat-button class="mat-button" color="warn" (click)="Timbra()">TIMBRA USCITA</button></div><!-- - HH:mm:ss' :'+0100'-->
            </mat-card-content>
        </mat-card>
    </div>
        <div fxLayout.xs="column" fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="center start">
            <div fxFlex.xs="100%" fxFlex="70%" *ngIf="access_level<3 || access_level>5">
              
                <div flexLayout="row">    
                    <mat-card *ngIf="access_level<3">
                            <mat-card-title class="direttore_titolo"><img src="../../assets/Risorsa25logo.png" height="45px" style="vertical-align:bottom; padding: 2px 20px 2px 10px"> Elenco Pazienti Direttore <button mat-button (click)="CaricaOrdine('Direttore')"><mat-icon>refresh</mat-icon></button><button *ngIf="access_level==1 || access_level==6 || access_level==7" mat-button (click)="MostraNascondi('Fisiatria')">Mostra/Nascondi</button></mat-card-title>
                            <mat-card-content>
                            <div flexLayout="row" fxLayout.xs="column" *ngIf="access_level==1 || access_level==2"  class="box-info">
                              <div fxFlex="20%">
                                  <mat-form-field fxFlexFill>
                                      <input matInput placeholder="Cognome" [(ngModel)]="paziente.cognome" name="paziente.cognome">
                                  </mat-form-field>
                              </div>
                              <div fxFlex="20%">
                                  <mat-form-field fxFlexFill>
                                      <input matInput placeholder="Nome" [(ngModel)]="paziente.nome" name="paziente.nome">
                                  </mat-form-field>
                              </div>
                              <div fxFlex="15%">
                                  <mat-form-field fxFlexFill>
                                      <input matInput type="date" placeholder="Data di nascita" [(ngModel)]="paziente.data_nascita">
                                  </mat-form-field>
                                </div>
                                <div fxFlex="15%">
                                  <mat-form-field fxFlexFill>
                                    <input matInput placeholder="Codice Fiscale" [(ngModel)]="cf" (ngModelChange)="EstraiData($event)">
                                </mat-form-field>
                              </div>
                                <div fxFlex="20%">
                                    <mat-form-field fxFlexFill>
                                        <mat-select matInput placeholder="Tipo" [(ngModel)]="paziente.tipo" name="paziente.tipo">
                                            <mat-option *ngFor="let tipo of tipi_pneumologia" value="{{tipo.tipo_visita}}">{{tipo.descrizione}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div fxFlex="10%">
                                    <button class="direttore" mat-button (click)="Add('Direttore')"><mat-icon>add</mat-icon></button>
                                </div>
                            </div><br/><br/>
                            <div class="barrato" flexLayout="row" *ngFor="let paziente of ordine['Direttore']; let o=index;">
                                <div class="fix-height" flexLayout="row">
                                    <div fxFlex="5%"><b>#{{o+1}} - </b></div>
                                    <div fxHide.xs='true' *ngIf="access_level<3" fxFlex="4%"><a class="direttore" (click)="Del('Direttore',paziente.id)"><mat-icon>delete</mat-icon></a></div> 
                                    <div *ngIf="access_level==1" fxFlex="4%"><a class="direttore" (click)="OpenDialog(paziente)"><mat-icon>remove_red_eye</mat-icon></a></div>
                                    <div fxHide.xs='true' *ngIf="access_level<3" fxFlex="4%"><a class="direttore" (click)="StampaCertificato(paziente,get_tipo_visita(paziente.tipo, 'Pneumologia',1),'Pneumologia')"><mat-icon>description</mat-icon></a></div>
                                    <div fxFlex="50%" ><b>{{paziente.cognome}}</b>&nbsp;&nbsp;&nbsp;{{paziente.nome}}&nbsp;<span class="data_nascita">{{paziente.data_nascita | date:'dd/MM/yyyy'}} - Ora di Arrivo: {{paziente.ora_accesso}} - </span><a class="direttore" (click)="RefreshPaziente(paziente)">^^</a></div> 
                                    <div fxHide.xs='true' fxFlex="30%">{{get_tipo_visita(paziente.tipo, 'Pneumologia',1)}}</div>
                                    <div fxFlex="3%"><a class="direttore" (click)="routing(paziente.idscheda,'1',paziente.tipo)">>></a></div>  
                                </div>
                                <div class="fix-height-sub" flexLayout="row">
                                    <div fxFlex *ngIf="paziente.eseguito"><b>Eseguito:</b> {{paziente.eseguito}}</div>
                                    <div fxFlex *ngIf="!paziente.eseguito"><i>In attesa</i></div>
                                </div>     
                                <!--<span>
                                            <div fxFlex="2%"><b>#{{k+1}}</b></div><div fxHide.xs='true' *ngIf="access_level<3" fxFlex="3%"><a (click)="Del('Fisiatria',paziente.id)"><mat-icon>delete</mat-icon></a></div> <div *ngIf="access_level==1" fxFlex="5%"><a [routerLink]="['archivio',paziente.idscheda]"><mat-icon>remove_red_eye</mat-icon></a></div><div fxFlex="15%" fxFlex.xs="30%">{{paziente.cognome}}</div> <div fxFlex="20%">{{paziente.nome}}</div> <div fxHide.xs='true' fxFlex="20%">{{paziente.tipo}}</div> <div fxFlex="30%"><div *ngIf="paziente.eseguito"><b>Eseguito:</b> {{paziente.eseguito}}</div><div *ngIf="!paziente.eseguito">In attesa</div></div> <div fxFlex="5%"><a (click)="routing(paziente.idscheda,'2',paziente.tipo)">>></a></div>
                                    </span>-->
                            </div>
                            </mat-card-content>
                        </mat-card>
                    </div>
                  <div flexLayout="row">    
                  <mat-card *ngIf="access_level<3 || access_level==9">
                          <mat-card-title class="blue"><img src="../../assets/Risorsa79logo.png" height="45px" style="vertical-align:bottom; padding: 2px 20px 2px 10px"> Elenco Pazienti Per Fisiatria <button mat-button (click)="CaricaOrdine('Fisiatria')"><mat-icon>refresh</mat-icon></button><button *ngIf="access_level==1 || access_level==6 || access_level==7" mat-button (click)="MostraNascondi('Fisiatria')">Mostra/Nascondi</button></mat-card-title>
                          <mat-card-content>
                          <div flexLayout="row" fxLayout.xs="column" *ngIf="access_level==1 || access_level==2"  class="box-info">
                            <div fxFlex="20%">
                                <mat-form-field fxFlexFill>
                                    <input matInput placeholder="Cognome" [(ngModel)]="paziente.cognome" name="paziente.cognome">
                                </mat-form-field>
                            </div>
                              <div fxFlex="20%">
                                  <mat-form-field fxFlexFill>
                                      <input matInput placeholder="Nome" [(ngModel)]="paziente.nome" name="paziente.nome">
                                  </mat-form-field>
                              </div>
                              <div fxFlex="15%">
                                  <mat-form-field fxFlexFill>
                                      <input matInput type="date" placeholder="Data di nascita" [(ngModel)]="paziente.data_nascita">
                                  </mat-form-field>
                                </div>
                                <div fxFlex="15%">
                                  <mat-form-field fxFlexFill>
                                    <input matInput placeholder="Codice Fiscale" [(ngModel)]="cf" (ngModelChange)="EstraiData($event)">
                                </mat-form-field>
                              </div>
                              <div fxFlex="20%">
                                  <mat-form-field fxFlexFill>
                                      <mat-select matInput placeholder="Tipo" [(ngModel)]="paziente.tipo" name="paziente.tipo">
                                          <mat-option *ngFor="let tipo of tipi_fisiatria" value="{{tipo.tipo_visita}}">{{tipo.descrizione}}</mat-option>
                                      </mat-select>
                                  </mat-form-field>
                              </div>
                              <div fxFlex="10%">
                                  <button class="cs" mat-button (click)="Add('Fisiatria')"><mat-icon>add</mat-icon></button>
                              </div>
                          </div><br/><br/>
                          <div class="barrato" flexLayout="row" *ngFor="let paziente of ordine['Fisiatria']; let k=index;">
                              <div class="fix-height" flexLayout="row">
                                  <div fxFlex="5%"><b>#{{k+1}} - </b></div>
                                  <div fxHide.xs='true' *ngIf="access_level<3" fxFlex="4%"><a class="cs" (click)="Del('Fisiatria',paziente.id)"><mat-icon>delete</mat-icon></a></div> 
                                  <div *ngIf="access_level==1" fxFlex="4%"><a class="cs" (click)="OpenDialog(paziente)"><mat-icon>remove_red_eye</mat-icon></a></div>
                                  <div fxHide.xs='true' *ngIf="access_level<3" fxFlex="4%"><a class="cs" (click)="StampaCertificato(paziente,get_tipo_visita(paziente.tipo, 'Fisiatria',1),'Fisiatria')"><mat-icon>description</mat-icon></a></div>
                                  <div fxFlex="50%" ><b>{{paziente.cognome}}</b>&nbsp;&nbsp;&nbsp;{{paziente.nome}}&nbsp;<span class="data_nascita">{{paziente.data_nascita | date:'dd/MM/yyyy'}} - Ora di Arrivo: {{paziente.ora_accesso}} - </span><a class="cs" (click)="RefreshPaziente(paziente)">^^</a></div> 
                                  <div fxHide.xs='true' fxFlex="30%">{{get_tipo_visita(paziente.tipo, 'Fisiatria',1)}}</div>
                                  <div fxFlex="3%"><a class="cs" (click)="routing(paziente.idscheda,'2',paziente.tipo)">>></a></div>  
                              </div>
                              <div class="fix-height-sub" flexLayout="row">
                                <div fxFlex *ngIf="paziente.eseguito"><b>Eseguito:</b> {{paziente.eseguito}}</div>
                                <div fxFlex *ngIf="!paziente.eseguito"><i>In attesa</i></div>
                            </div>     
                              <!--<span>
                                          <div fxFlex="2%"><b>#{{k+1}}</b></div><div fxHide.xs='true' *ngIf="access_level<3" fxFlex="3%"><a (click)="Del('Fisiatria',paziente.id)"><mat-icon>delete</mat-icon></a></div> <div *ngIf="access_level==1" fxFlex="5%"><a [routerLink]="['archivio',paziente.idscheda]"><mat-icon>remove_red_eye</mat-icon></a></div><div fxFlex="15%" fxFlex.xs="30%">{{paziente.cognome}}</div> <div fxFlex="20%">{{paziente.nome}}</div> <div fxHide.xs='true' fxFlex="20%">{{paziente.tipo}}</div> <div fxFlex="30%"><div *ngIf="paziente.eseguito"><b>Eseguito:</b> {{paziente.eseguito}}</div><div *ngIf="!paziente.eseguito">In attesa</div></div> <div fxFlex="5%"><a (click)="routing(paziente.idscheda,'2',paziente.tipo)">>></a></div>
                                  </span>-->
                          </div>
                          </mat-card-content>
                      </mat-card>
                  </div>

                  <div flexLayout="row">
                      <mat-card *ngIf="access_level<3 || access_level==10">
                          <mat-card-title class="green"><img src="../../assets/Risorsa77logo.png" height="45px" style="vertical-align:bottom; padding: 2px 20px 2px 10px"> Elenco Pazienti Per Pneumologia <button mat-button (click)="CaricaOrdine('Pneumologia')"><mat-icon>refresh</mat-icon></button><button *ngIf="access_level==1 || access_level==6 || access_level==7" mat-button (click)="MostraNascondi('Pneumologia')">Mostra/Nascondi</button></mat-card-title>
                          <mat-card-content>
                              <div flexLayout="row" fxLayout.xs="column" *ngIf="access_level==1 || access_level==2"  class="box-info">
                                <div fxFlex="20%">
                                    <mat-form-field fxFlexFill>
                                        <input matInput placeholder="Cognome" [(ngModel)]="paziente.cognome" name="paziente.cognome">
                                    </mat-form-field>
                                </div>
                                <div fxFlex="20%">
                                    <mat-form-field fxFlexFill>
                                        <input matInput placeholder="Nome" [(ngModel)]="paziente.nome" name="paziente.nome">
                                    </mat-form-field>
                                </div>
                                <div fxFlex="15%">
                                    <mat-form-field fxFlexFill>
                                        <input matInput type="date" placeholder="Data di nascita" [(ngModel)]="paziente.data_nascita">
                                    </mat-form-field>
                                  </div>
                                  <div fxFlex="15%">
                                    <mat-form-field fxFlexFill>
                                      <input matInput placeholder="Codice Fiscale" [(ngModel)]="cf" (ngModelChange)="EstraiData($event)">
                                  </mat-form-field>
                                </div>
                                  <div fxFlex="20%">
                                      <mat-form-field fxFlexFill>
                                          <mat-select matInput placeholder="Tipo" [(ngModel)]="paziente.tipo" name="paziente.tipo">
                                          <mat-option *ngFor="let tipo of tipi_pneumologia" value="{{tipo.tipo_visita}}">{{tipo.descrizione}}</mat-option>
                                          </mat-select>
                                      </mat-form-field>
                                  </div>
                                  <div fxFlex="10%">
                                  <button class="m2" mat-button (click)="Add('Pneumologia')"><mat-icon>add</mat-icon></button>
                                  </div>
                              </div><br/><br/>
                              <div class="barrato" flexLayout="row" *ngFor="let paziente of ordine['Pneumologia']; let j=index;">
                                  <div class="fix-height" flexLayout="row">
                                      <div fxFlex="5%"><b>#{{j+1}} - </b></div>
                                      <div fxHide.xs='true' *ngIf="access_level<3" fxFlex="4%"><a class="m2" (click)="Del('Pneumologia',paziente.id)"><mat-icon>delete</mat-icon></a></div> 
                                      <div *ngIf="access_level==1" fxFlex="4%"><a class="m2"  (click)="OpenDialog(paziente)" ><mat-icon>remove_red_eye</mat-icon></a></div>
                                      <div fxHide.xs='true' *ngIf="access_level<3" fxFlex="4%"><a class="m2" (click)="StampaCertificato(paziente,get_tipo_visita(paziente.tipo, 'Pneumologia',1),'Pneumologia')"><mat-icon>description</mat-icon></a></div>
                                      <div fxFlex="50%" ><b>{{paziente.cognome}}</b>&nbsp;&nbsp;&nbsp;{{paziente.nome}}&nbsp;<span class="data_nascita">{{paziente.data_nascita | date:'dd/MM/yyyy'}} - Ora di Arrivo: {{paziente.ora_accesso}} - </span><a class="m2" (click)="RefreshPaziente(paziente)">^^</a></div> 
                                      <div fxHide.xs='true' fxFlex="30%">{{get_tipo_visita(paziente.tipo, 'Pneumologia',1)}}</div>
                                      <div fxFlex="3%"><a class="m2" (click)="routing(paziente.idscheda,'1',get_tipo_visita(paziente.tipo, 'Pneumologia',2))">>></a></div>  
                                  </div>
                                  <div class="fix-height-sub" flexLayout="row">
                                    <div fxFlex *ngIf="paziente.eseguito"><b>Eseguito:</b> {{paziente.eseguito}}</div>
                                    <div fxFlex *ngIf="!paziente.eseguito"><i>In attesa</i></div>
                                </div>      
                                 <!-- <span>
                                              <div fxFlex="2%"><b>#{{j+1}}</b></div><div fxHide.xs='true' *ngIf="access_level<3" fxFlex="3%"><a (click)="Del('Pneumologia',paziente.id)"><mat-icon>delete</mat-icon></a></div> <div *ngIf="access_level==1" fxFlex="5%"><a [routerLink]="['archivio',paziente.idscheda]"><mat-icon>remove_red_eye</mat-icon></a></div><div fxFlex="15%" fxFlex.xs='30%'>{{paziente.cognome}}</div> <div fxFlex="20%">{{paziente.nome}}</div> <div fxHide.xs='true' fxFlex="20%">{{paziente.tipo}}</div> <div fxFlex="30%"><div *ngIf="paziente.eseguito"><b>Eseguito:</b> {{paziente.eseguito}}</div><div *ngIf="!paziente.eseguito">In attesa</div></div> <div fxFlex="5%"><a (click)="routing(paziente.idscheda,'1',paziente.tipo)">>></a></div>
                                      </span>-->
                              </div>
                          </mat-card-content>
                      </mat-card>
                  </div>
                  <div flexLayout="row">
                      <mat-card *ngIf="access_level==1 || access_level==6 || access_level==7 || access_level==8">
                          <mat-card-title class="red"><img src="../../assets/Risorsa78logo.png" height="45px" style="vertical-align:bottom; padding: 2px 20px 2px 10px"> Elenco Pazienti Per Cardiologia <button mat-button (click)="CaricaOrdine('Cardiologia')"><mat-icon>refresh</mat-icon></button><button *ngIf="access_level==1 || access_level==6 || access_level==7" mat-button (click)="MostraNascondi('Cardiologia')">Mostra/Nascondi</button></mat-card-title>
                          <mat-card-content>
                              <div fxLayout.xs="column" flexLayout="row"  *ngIf="access_level==1 || access_level==6 || access_level==7"  class="box-info">
                                <div fxFlex="20%">
                                    <mat-form-field fxFlexFill>
                                        <input matInput placeholder="Cognome" [(ngModel)]="paziente.cognome" name="paziente.cognome">
                                    </mat-form-field>
                                </div>
                                <div fxFlex="20%">
                                    <mat-form-field fxFlexFill>
                                        <input matInput placeholder="Nome" [(ngModel)]="paziente.nome" name="paziente.nome">
                                    </mat-form-field>
                                </div>
                                <div fxFlex="15%">
                                    <mat-form-field fxFlexFill>
                                        <input matInput type="date" placeholder="Data di nascita" [(ngModel)]="paziente.data_nascita">
                                    </mat-form-field>
                                  </div>
                                  <div fxFlex="15%">
                                    <mat-form-field fxFlexFill>
                                      <input matInput placeholder="Codice Fiscale" [(ngModel)]="cf" (ngModelChange)="EstraiData($event)">
                                  </mat-form-field>
                                </div>
                                  <div fxFlex="20%">
                                      <mat-form-field fxFlexFill>
                                          <mat-select matInput placeholder="Tipo" [(ngModel)]="paziente.tipo" name="paziente.tipo">
                                          <mat-option *ngFor="let tipo of tipi_cardiologia"  value="{{tipo.tipo_visita}}">{{tipo.descrizione}}</mat-option>
                                          </mat-select>
                                      </mat-form-field>
                                  </div>
                                  <div fxFlex="10%">
                                  <button class="cdcp" mat-button (click)="Add('Cardiologia')"><mat-icon>add</mat-icon></button>
                                  </div>
                              </div><br/><br/>
                              <div class="barrato" flexLayout="row" *ngFor="let paziente of ordine['Cardiologia']; let i=index;">
                                      <div class="fix-height" flexLayout="row">
                                          <div fxFlex="5%"><b>#{{i+1}} - </b></div>
                                          <div fxHide.xs='true' *ngIf="access_level==1 || access_level==6" fxFlex="4%"><a class="cdcp" (click)="Del('Cardiologia',paziente.id)"><mat-icon>delete</mat-icon></a></div> 
                                          <div fxHide.xs='true' *ngIf="access_level==1 || access_level==6" fxFlex="4%"><a class="cdcp" (click)="OpenDialog(paziente)"><mat-icon>remove_red_eye</mat-icon></a></div>
                                          <div fxHide.xs='true' *ngIf="access_level==1 || access_level==6" fxFlex="4%"><a class="cdcp" (click)="StampaCertificato(paziente,get_tipo_visita(paziente.tipo, 'Cardiologia',1),'Cardiologia')"><mat-icon>description</mat-icon></a></div>
                                          <div fxFlex="50%" ><b>{{paziente.cognome}}</b>&nbsp;&nbsp;&nbsp;{{paziente.nome}}&nbsp;<span class="data_nascita">{{paziente.data_nascita | date:'dd/MM/yyyy'}} - Ora di Arrivo: {{paziente.ora_accesso}} - </span><a class="cdcp" (click)="RefreshPaziente(paziente)">^^</a></div> 
                                          <div fxHide.xs='true' fxFlex="30%">{{get_tipo_visita(paziente.tipo, 'Cardiologia',1)}}</div>
                                          <div fxFlex="3%"><a class="cdcp" (click)="routing(paziente.idscheda,'0',get_tipo_visita(paziente.tipo, 'Cardiologia',2))">>></a></div>  
                                      </div>
                                      <div class="fix-height-sub" flexLayout="row">
                                      <div fxFlex *ngIf="paziente.eseguito"><b>Eseguito:</b> {{paziente.eseguito}}</div>
                                      <div fxFlex *ngIf="!paziente.eseguito"><i>In attesa</i></div>
                                      </div>
                              </div>
                          </mat-card-content>
                      </mat-card>
                  </div>
            </div>
            <div fxFlex>
                <mat-card *ngIf="access_level<2 || access_level==6">
                    <mat-card-title class="direttore_titolo">UPLOAD CSV PAZIENTI</mat-card-title>
                    <mat-card-content>
                        <input type="file" (change)="UploadPazientiCSV($event.target.files[0])">
                        <br/>
                        <p style="white-space: pre-line">{{conferma_inserimento}}</p>                        
                    </mat-card-content>
                </mat-card>
              <mat-card *ngIf="access_level==1 || CheckNota()">
                <mat-card-title class="direttore_titolo">Note del Direttore <span *ngIf="access_level==1"><button color="accent" mat-button (click)="Nuovanota()"><mat-icon>add</mat-icon></button></span></mat-card-title>
                <mat-card-subtitle>Indicazioni per i dipendenti di tutte le strutture</mat-card-subtitle>
                <mat-divider></mat-divider>
                <div *ngFor="let nota of note_direttore">
                  <mat-card *ngIf="nota.tipo==0 || nota.tipo==access_level || access_level==1">
                    {{nota.nota}}<br/>
                    <button mat-icon-button *ngIf="access_level==1" (click)="ModificaNota(nota.id)"><mat-icon>edit</mat-icon></button>
                    <button mat-icon-button *ngIf="access_level==1" (click)="ArchiviaNota(nota.id)"><mat-icon>delete</mat-icon></button>
                    <span *ngIf="access_level==1"><i>Nota visibile a <b>{{get_tipo(nota)}}</b></i></span>
                  </mat-card>
                </div>
              </mat-card>
              <mat-card *ngIf="access_level<=2 || access_level==11">
                <mat-card-title>Polisonnografie in corso</mat-card-title>
                <mat-card-content>
                    <p *ngFor="let polisonno of array_polisonno">
                        <b>{{polisonno.cognome}}</b> - <i>STATO: <span *ngIf="!polisonno.data_esecuzione && !polisonno.data_refertazione" style="color:red">In attesa di esecuzione</span><span *ngIf="polisonno.data_esecuzione" style="color:blueviolet">- Eseguita -</span><span *ngIf="polisonno.data_refertazione" style="color:green">- Refertata -</span></i><br/>Note: {{polisonno.note}}<br/>Telefono: {{polisonno.telefono}}<br/>---------------------------
                    </p>
                </mat-card-content>
              </mat-card>
              <mat-card *ngIf="access_level>2">
                  <mat-card-title>Invia Segnalazione all'Amministratore del Sistema</mat-card-title>
                  <mat-card-content>
                      <div fxLayout="column">
                          <div fxFlex="100%">
                              <mat-form-field fxFlexFill>
                                  <input matInput placeholder="Segnalazione" [(ngModel)]="segnalazioni.segnalazione" name="segnalazioni.segnalazione">
                              </mat-form-field>
                          </div>
                          <div fxFlex="100%">
                              <button mat-raised-button color="primary" (click)="InserisciSegnalazione()">Segnala</button>
                          </div>
                      </div>
                  </mat-card-content>
              </mat-card>
              <mat-card *ngIf="access_level==1 || access_level==6">
                  <mat-card-title class="red">Aggiungi Prestazioni Private</mat-card-title>
                  <mat-card-content>
                      <div fxLayout="column">
                          <div fxFlex="100%">
                              <mat-form-field fxFlexFill>
                                      <input matInput placeholder="Paziente" [(ngModel)]="privato.paziente" name="privato.paziente">
                              </mat-form-field>
                          </div>
                          <div fxFlex="100%">
                              <mat-form-field fxFlexFill>
                                      <mat-select matInput placeholder="Medico" [(ngModel)]="privato.username" name="privato.username">
                                      <mat-option *ngFor="let user of users" value="{{user.username}}">{{user.username}}</mat-option>
                                      </mat-select>
                              </mat-form-field>
                          </div>
                          <div fxFlex="100%">
                              <mat-form-field fxFlexFill>
                                      <mat-select matInput placeholder="Prestazione" [(ngModel)]="privato.prestazione" name="privato.prestazione">
                                          <mat-option value="Visita">Visita</mat-option>
                                          <mat-option value="Visita+ECG">Visita+ECG</mat-option>
                                          <mat-option value="Visita+ECG+ECO">Visita+ECG+ECO</mat-option>
                                          <mat-option value="Test da Sforzo">Test da Sforzo</mat-option>
                                          <mat-option value="ECO">ECO</mat-option>
                                      </mat-select>
                              </mat-form-field>
                          </div>
                          <div fxFlex="100%">
                                      <input type="date" [(ngModel)]="privato.data" name="privato.data">
                          </div><br/>
                          <div fxFlex="100%">
                              <button mat-raised-button class="cdcp" (click)="InserisciPrestazionePrivata()">Invia</button>
                          </div>
                      </div>
                  </mat-card-content>
              </mat-card>
              <mat-card *ngIf="access_level==1">
                      <mat-card-title class="direttore_titolo">Operatori attivi <button color="accent" mat-button (click)="Refresh(1)"><mat-icon>refresh</mat-icon></button></mat-card-title>
                      <mat-card-subtitle>Terapisti, Collaboratori, Medici, Infermieri ecc.</mat-card-subtitle>
                      <mat-divider></mat-divider>
                      <mat-list class="will-load alert-list">
                          <mat-list-item *ngIf="!utenti">Nessun operatore attivo al momento</mat-list-item>
                          <mat-list-item *ngFor="let utente of utenti">{{utente.codice_operatore}} - {{utente.orario_ingresso}}</mat-list-item>
                      </mat-list>
                    </mat-card>
              <mat-card *ngIf="access_level==1" >
                      <mat-card-title class="blue">Statistiche Terapisti</mat-card-title>
                      <mat-card-subtitle>% prestazioni eseguite per ogni terapista</mat-card-subtitle>
                      <mat-divider></mat-divider>
                      <mat-list class="will-load alert-list">
                          <mat-list-item *ngFor="let valori of array_valori">{{valori.name}}: {{valori.value}}/{{numero_terapie}}({{(valori.value/numero_terapie)*100 | number:'2.1-2'}}%)</mat-list-item>
                      </mat-list>
              </mat-card>
              <div fxLayout="row" *ngIf="access_level<=3" fxLayout.xs="column">
                <div fxFlex="50%" fxFlex.xs="100%" *ngIf="access_level==3">
                    <mat-card>
                        <mat-card-title class="blue">Pazienti abilitati da trattare <button color="accent" mat-button (click)="Refresh(2)"><mat-icon>refresh</mat-icon></button></mat-card-title>
                        <mat-card-content>
                            <mat-form-field appearance="standard" fxFlexFill>
                                <mat-label>Cerca scrivendo cognome e nome senza spazi</mat-label>
                                <input matInput (keyup)="applyFilter($event)" placeholder="Cerca" #input>
                              </mat-form-field>
                              
                              <div class="mat-elevation-z8">
                                <table mat-table [dataSource]="dataSource" matSort style="width: 100%">
                              
                                  <!-- utente -->
                                  <ng-container matColumnDef="utente">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Paziente </th>
                                    <td mat-cell *matCellDef="let row"><br/> <b>{{row.cognome}} {{row.nome}}</b> - {{row.data_nascita | date:'dd/MM/yyyy'}} - <button mat-flat-button (click)="OpenDialog(row)">schede paziente</button><br/><hr/><b>DIAGNOSI:</b> {{row.scheda.diagnosi}}<span *ngIf="row.scheda.note_operative"><br/> <b>NOTE OPERATIVE:</b> {{row.scheda.note_operative}}</span><br/><br/>{{row.terapia}} - {{row.segmento}}<br/><br/></td>
                                  </ng-container>
                              
                                
                                  <!-- terapia -->
                                  <ng-container matColumnDef="terapia">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Esegui </th>
                                    <td mat-cell *matCellDef="let row">
                                         <button mat-raised-button color="primary" (click)="Esegui_terapia(row.id_segmento,row.id_terapia,row.id_scheda, row)">Esegui</button>
                                         <br/><br/>
                                         <mat-select [(ngModel)]="box" name="box">
                                            <mat-option value="P">P</mat-option>
                                            <mat-option value="1">1</mat-option>
                                            <mat-option value="2">2</mat-option>
                                            <mat-option value="3">3</mat-option>
                                            <mat-option value="4">4</mat-option>
                                            <mat-option value="5">5</mat-option>
                                            <mat-option value="6">6</mat-option>
                                            <mat-option value="7">7</mat-option>
                                            <mat-option value="8">8</mat-option>
                                            <mat-option value="9">9</mat-option>
                                            <mat-option value="10">10</mat-option>
                                        </mat-select> 
                                    </td>
                                  </ng-container>
                              
                                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                              
                                  <!-- Row shown when there is no matching data. -->
                                  <tr class="mat-row" *matNoDataRow>
                                    <td class="mat-cell" colspan="2">No data matching the filter "{{input.value}}"</td>
                                  </tr>
                                </table>
                              
                                <mat-paginator [pageSizeOptions]="[20, 50, 100]" aria-label="Select page of users"></mat-paginator>
                              </div>
                        </mat-card-content>
                    </mat-card>
                </div>
                <div fxFlex fxFlex.xs="100%">
                    <mat-card>
                        <mat-card-title class="blue">Pazienti trattati oggi <button color="accent" mat-button (click)="Refresh(0)"><mat-icon>refresh</mat-icon></button></mat-card-title>
                        <mat-card-subtitle>Tutti i pazienti trattati in giornata</mat-card-subtitle>
                        <mat-divider></mat-divider>
                        <div>
                            Data alternativa: <input type="date" [(ngModel)]="data_alternativa" name="data_alternativa"> <button (click)="VediPrecedente()">>></button>
                        </div>
                            <div *ngFor="let terapia of terapie_eseguite">
                              <p class="medium">{{terapia.cognome}} {{terapia.nome}}</p>
                              <p class="mini">{{terapia.timestamp | date:'shortTime'}} - {{terapia.descrizione}} - {{terapia.username}}</p>
                              <mat-divider></mat-divider>
                            </div>
                    </mat-card>
                </div>
              </div>
              
          
            </div>
            
            </div>
