import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import { AuthenticationService } from '../_services';

@Component({
  selector: 'app-cambio-password',
  templateUrl: './cambio-password.component.html',
  styleUrls: ['./cambio-password.component.scss']
})
export class CambioPasswordComponent implements OnInit {

  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  error = '';
  breakpoint;
  equal_password=0;
  number_password=0;
  char_password=0;

  constructor(
      private formBuilder: FormBuilder,
      private route: ActivatedRoute,
      private router: Router,
      private authenticationService: AuthenticationService
  ) { 
  }



  ngOnInit() {
      this.loginForm = this.formBuilder.group({
          username: ['', Validators.required],
          password: ['', Validators.required],
          new_password: ['', [Validators.required,this.minLength(6),this.CheckNumber, this.CheckChar]]
      });
      this.breakpoint = (window.innerWidth <= 700) ? 1 : 3;
  }

    // Validators
    minLength(minimum) {
      return function(input) {
        return input.value.length >= minimum ? null : { minLength: true };
      };
    }

    CheckNumber(input: FormControl){
      let find=0;
      for(let i=0; i<10;i++){
        if(input.value.indexOf(i)>=0) find=1
      }
      return find==1? null : {needNumber: true}
    }

    CheckChar(input: FormControl){
      let char=['$','@', '€', '&', '!', '%'];
      let find=0;
      for(let i=0; i<char.length;i++){
        if(input.value.indexOf(char[i])>=0) find=1
      }
      return find==1? null : {needChar: true}
    }


    // Fine Validators

  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }
  get new_password() {return this.loginForm.get('new_password')}
  get check_password() {return this.loginForm.get('check_password')}

  onResize(event) {
      this.breakpoint = (event.target.innerWidth <= 700) ? 1 : 3;
    }

  onSubmit() {
      this.submitted = true;

      // stop here if form is invalid
      if (this.loginForm.invalid) {
          return;
      }

      this.loading = true;
      this.authenticationService.chgpsw(this.f.username.value.toUpperCase(), this.f.password.value.toUpperCase(), this.f.new_password.value.toUpperCase())
          .pipe(first())
          .subscribe(
              data => {
                  if(data) {
                    window.alert("Password cambiata correttamente");
                    this.router.navigate(['/']);}
                  else this.error="La password vecchia non coincide con quella a sistema, se hai dimenticato la password vecchia contatta l'amministratore di sistema.";
                  this.loading=false
              },
              error => {
                  this.error = error;
                  this.loading = false;
              });
  }

}
