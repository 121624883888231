<mat-drawer-container class="example-container" [hasBackdrop]="true">
    <mat-drawer #drawer [mode]="over"> 
        <mat-nav-list >
            <ng-template let-item let-last="last" ngFor [ngForOf]="menu">
              <a mat-list-item [routerLink]="[item.route]" (click)="drawer.toggle()"><span *ngIf="item.svg"><mat-icon svgIcon="{{item.svg}}"></mat-icon></span><span *ngIf="!item.svg"><mat-icon style='color: {{item.color}}'>{{item.icon}}</mat-icon></span>&nbsp;{{item.title}}</a>
            </ng-template>
          </mat-nav-list>
      <mat-nav-list>
          <a mat-list-item (click)="logout()"><mat-icon style="color:red">exit_to_app</mat-icon>&nbsp;Logout</a>
      </mat-nav-list>
    </mat-drawer>
  
    <mat-drawer-content>
        <p>
        <mat-toolbar color="primary">
              <button mat-icon-button (click)="drawer.toggle()"><mat-icon>menu</mat-icon></button>
              <mat-icon class="mat-icon-logo logoToolbar" svgIcon=""></mat-icon>
              <span><img src="../../assets/Risorsa10logo.png" height="45px" style="vertical-align:bottom"> - {{Welcome}} - {{data_now | date: 'EEEE dd MMMM yyyy' }}</span>
        </mat-toolbar>
        </p>
        <div fxLayout="row" id="menu_sotto" fxHide.xs>
          <div *ngFor="let item of menu" [fxFlex]="flex_items">
            <a [routerLink]="[item.route]"><span *ngIf="item.svg"><mat-icon svgIcon="{{item.svg}}"></mat-icon></span><span *ngIf="!item.svg"><mat-icon style='color: {{item.color}}'>{{item.icon}}</mat-icon></span>&nbsp;{{item.title}}</a>
          </div>
          <div [fxFlex]="flex_items">
            <a mat-list-item (click)="logout()"><mat-icon style="color:red">exit_to_app</mat-icon>&nbsp;Logout</a>
          </div>
        </div>
    <mat-card>
      <router-outlet></router-outlet>
    </mat-card>
    <p>
          <span class="mat-caption">Copyright &copy; 2021. Tutti i diritti sono riservati.&nbsp;</span>
          <a href="https://www.gmsanita.it" target="_blank">https://www.gmsanita.it</a>
    </p>
    </mat-drawer-content>
</mat-drawer-container>