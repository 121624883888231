import { NgModule } from '@angular/core';
import {LOCALE_ID} from '@angular/core';
import { registerLocaleData } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import {MatCardModule} from '@angular/material/card';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatFormFieldModule} from '@angular/material/form-field';
import { FlexLayoutModule } from '@angular/flex-layout';
import {MatTooltipModule,MatTabsModule,MatSelectModule, MatBadgeModule, MatAutocompleteModule, MatSortModule, MatInputModule, MatButtonModule, MatIconModule, MatSidenavModule, MatListModule, MatToolbarModule, MatTableModule, MatMenuModule, MatSnackBarModule, MatDialogModule, MatCheckboxModule, MatTooltip, MatPaginatorModule, MatDatepickerModule, MatExpansionModule, MatRadioModule } from '@angular/material';
// used to create fake backend
import { AuthGuard} from './_helpers';
import { NgxBarcodeModule } from 'ngx-barcode';

import { AppComponent } from './app.component';
import { appRoutingModule } from './app.routing';
import localeIt from '@angular/common/locales/it';
registerLocaleData(localeIt);

import { CovalentLayoutModule } from '@covalent/core/layout';
import { CovalentStepsModule  } from '@covalent/core/steps';

import { JwtInterceptor, ErrorInterceptor } from './_helpers';
import { HomeComponent } from './home';
import { LoginComponent } from './login';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthenticationService, GlobalServices } from './_services';
import { DashboardComponent} from './dashboard/dashboard.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { SignaturePadModule } from 'angular2-signaturepad'; 
import { NoteDirettoreComponent } from './note-direttore/note-direttore.component';
import { ArchivioPazientiComponent } from './archivio-pazienti/archivio-pazienti.component';
import { PazienteComponent } from './archivio-pazienti/paziente/paziente.component';
import { VisiteComponent } from './archivio-pazienti/visite/visite.component';
import { ElencoPazientiComponent } from './archivio-pazienti/elenco-pazienti/elenco-pazienti.component';
import { PendingChangesGuard } from './_guards/deactivate';
import { MatriciComponent } from './matrici/matrici.component';
import { HolterComponent } from './holter/holter.component';
import { PlantariComponent } from './plantari/plantari.component';
import { ImpostazioniComponent } from './impostazioni/impostazioni.component';
import { StatisticheComponent } from './statistiche/statistiche.component';
import { RiepilogoPresenzeDirettoreComponent } from './riepilogo-presenze-direttore/riepilogo-presenze-direttore.component';
import { AmministrazioneUtentiComponent } from './amministrazione-utenti/amministrazione-utenti.component';
import { ElencoUtentiComponent } from './amministrazione-utenti/elenco-utenti/elenco-utenti.component';
import { UtenteComponent } from './amministrazione-utenti/utente/utente.component';
import { TimbriComponent } from './timbri/timbri.component';
import { SchedaComponent } from './scheda/scheda.component';
import { CambioPasswordComponent } from './cambio-password/cambio-password.component';
import { NotaPolisonnoDialog, PolisonnografiaComponent } from './polisonnografia/polisonnografia.component';
import { PrescrizioneBpcoComponent } from './prescrizione-bpco/prescrizione-bpco.component';
import { AngularEditorModule } from '@kolkov/angular-editor';

@NgModule({
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    HttpClientModule,
    appRoutingModule,
    BrowserAnimationsModule,
    MatCardModule,
    MatGridListModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatSidenavModule,
    MatListModule,
    MatToolbarModule,
    MatTableModule,
    MatMenuModule,
    FormsModule,
    MatSnackBarModule,
    MatDialogModule,
    FlexLayoutModule,
    MatSelectModule,
    MatSortModule,
    MatAutocompleteModule,
    MatCheckboxModule,
    MatBadgeModule,
    NgxBarcodeModule,
    MatTabsModule,
    MatTooltipModule,
    MatPaginatorModule,
    MatDatepickerModule,
    MatExpansionModule,
    CalendarModule.forRoot({ provide: DateAdapter, useFactory: adapterFactory }),
    SignaturePadModule,
    CovalentLayoutModule,
    CovalentStepsModule,
    MatRadioModule,
    AngularEditorModule
],
declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    DashboardComponent,
    NoteDirettoreComponent,
    ArchivioPazientiComponent,
    ElencoPazientiComponent,
    PazienteComponent,
    VisiteComponent,
    MatriciComponent,
    HolterComponent,
    PlantariComponent,
    ImpostazioniComponent,
    StatisticheComponent,
    RiepilogoPresenzeDirettoreComponent,
    AmministrazioneUtentiComponent,
    ElencoUtentiComponent,
    UtenteComponent,
    TimbriComponent,
    SchedaComponent,
    CambioPasswordComponent,
    PolisonnografiaComponent,
    NotaPolisonnoDialog,
    PrescrizioneBpcoComponent
],
providers: [
    { provide: LOCALE_ID, useValue: 'it-IT' },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    AuthGuard,
    PendingChangesGuard,
    AuthenticationService,
    GlobalServices
],
entryComponents: [],
bootstrap: [AppComponent]
})
export class AppModule { }
