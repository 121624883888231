import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import {GlobalServices} from '../_services/index';

@Component({
  selector: 'app-archivio-pazienti',
  templateUrl: './archivio-pazienti.component.html',
  styleUrls: ['./archivio-pazienti.component.css']
})
export class ArchivioPazientiComponent implements OnInit {

  stato_opened;
  my_acl;

  constructor(private _changeDetectorRef: ChangeDetectorRef, private service: GlobalServices) { }

  ngOnInit() {
    this._changeDetectorRef.detectChanges();
    this.my_acl=this.service.get_access_level();
    if(this.my_acl==3) this.stato_opened=false;
    else this.stato_opened=true;
  }

}
