<mat-grid-list [cols]="breakpoint" rowHeight="800px" (window:resize)="onResize($event)">
    <mat-grid-tile></mat-grid-tile>
    <mat-grid-tile>
        <mat-card>
        <mat-card-title>CAMBIO PASSWORD</mat-card-title>
        <mat-card-content>
            <h3>Attenzione, la tua password è scaduta e deve essere rinnovata</h3>
            <p>Inserisci username, la tua vecchia password e la nuova password</p>
                <form name="loginForm" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                    <mat-form-field appearance="outline">
                        <mat-label>Username</mat-label>
                        <input matInput type="text" formControlName="username" name="username">
                    </mat-form-field><br/>
                    <mat-form-field appearance="outline">
                        <mat-label>Vecchia Password</mat-label>
                        <input matInput type="password" formControlName="password" name="password">
                    </mat-form-field><br/>
                    <h4 *ngIf="new_password.errors">Requisiti da rispettare:</h4>
                    <p class="alert alert-danger mt-3 mb-0" *ngIf="new_password.hasError('minLength')"><i>La password deve contenere almeno 6 caratteri</i></p>
                    <p class="alert alert-danger mt-3 mb-0" *ngIf="new_password.hasError('needNumber')"><i>la password deve contenere almeno un numero</i></p>
                    <p class="alert alert-danger mt-3 mb-0" *ngIf="new_password.hasError('needChar')"><i>la password deve contenere almeno uno di questi caratteri speciali $, @, €, &, !, %</i></p>
                    <br/>
                    <mat-form-field appearance="outline">
                        <mat-label>Nuova Password</mat-label>
                        <input matInput type="password" formControlName="new_password" name="new_password">
                    </mat-form-field><span *ngIf="!new_password.errors"><mat-icon color="primary">check</mat-icon></span><br/>
                    <div>
                    <button mat-raised-button color="primary" [disabled]="loading">Cambia Password</button>
                    </div>
                    <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
                </form>
            </mat-card-content>
        </mat-card>
    </mat-grid-tile>
    <mat-grid-tile></mat-grid-tile>
    </mat-grid-list>
