import { Component, OnInit } from '@angular/core';
import {Utenti, Tipo} from '../../_models';
import {GlobalServices} from '../../_services';
import {  Router } from '@angular/router';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-elenco-utenti',
  templateUrl: './elenco-utenti.component.html',
  styleUrls: ['./elenco-utenti.component.css']
})
export class ElencoUtentiComponent implements OnInit {

  elenco_utenti= new Array<Utenti>();
  elenco_tipo: Tipo[];
  displayedColumns: string[] = ['username', 'tipo_utente', 'nominativo', 'data_in','data_out','azioni'];

  constructor(
    private service: GlobalServices, 
    private router: Router,
    private notifiche_bottom: MatSnackBar
  ) { }

  ngOnInit() {
    this.service.get_function(this.service.apiGetUtenti+'/elenco').subscribe(
      response => {
        this.elenco_utenti=response;
      }
    );
    
  }

  routing(id: any){
    //console.log(id);
    this.router.navigate(['amministrazione-utenti/utente',id]);
  }



}
