<mat-card>
  <mat-card-title>
    Impostazioni
  </mat-card-title>
  <mat-card-content>
      <mat-tab-group>
          <mat-tab label="Centro Scoliosi s.r.l.">
              <br/>
              <div flexLayout="row">
                <div fxFlex="10%">path del logo: </div>
                <div fxFlex="90%">
                  <mat-form-field fxFlexFill>
                    <input matInput [(ngModel)]="cs.logo" name="cs.logo">
                  </mat-form-field>
                </div>
              </div>
              <div flexLayout="row">
                <div fxFlex="10%">Denominazione: </div>
                <div fxFlex="90%">
                  <mat-form-field fxFlexFill>
                    <input matInput [(ngModel)]="cs.denominazione" name="cs.denominazione">
                  </mat-form-field>
                </div>
              </div>
              <div flexLayout="row">
                <div fxFlex="10%">Indirizzo: </div>
                <div fxFlex="90%">
                  <mat-form-field fxFlexFill>
                    <input matInput [(ngModel)]="cs.indirizzo" name="cs.indirizzo">
                  </mat-form-field>
                </div>
              </div>
              <div flexLayout="row">
                <div fxFlex="10%">Accreditamento N.: </div>
                <div fxFlex="90%">
                  <mat-form-field fxFlexFill>
                    <input matInput [(ngModel)]="cs.accreditamento" name="cs.accreditamento">
                  </mat-form-field>
                </div>
              </div>
              <div flexLayout="row">
                  <div fxFlex="10%">P.IVA: </div>
                  <div fxFlex="90%">
                    <mat-form-field fxFlexFill>
                      <input matInput [(ngModel)]="cs.piva" name="cs.piva">
                    </mat-form-field>
                  </div>
                </div>
              <div flexLayout="row">
                <div fxFlex="10%">Direttore Sanitario: </div>
                <div fxFlex="90%">
                  <mat-form-field fxFlexFill>
                    <input matInput [(ngModel)]="cs.direttoresanitario" name="cs.direttoresanitario">
                  </mat-form-field>
                </div>
              </div>
              <div flexLayout="row">
                <div fxFlex="10%">N.ro di telefono: </div>
                <div fxFlex="90%">
                  <mat-form-field fxFlexFill>
                    <input matInput [(ngModel)]="cs.telefono" name="cs.telefono">
                  </mat-form-field>
                </div>
              </div>
              <div flexLayout="row">
                <div fxFlex="10%">E-mail: </div>
                <div fxFlex="90%">
                  <mat-form-field fxFlexFill>
                    <input matInput [(ngModel)]="cs.email" name="cs.email">
                  </mat-form-field>
                </div>
              </div>
              <div flexLayout="row">
                <div fxFlex="10%">Sito Web: </div>
                <div fxFlex="90%">
                  <mat-form-field fxFlexFill>
                    <input matInput [(ngModel)]="cs.sito" name="cs.sito">
                  </mat-form-field>
                </div>
              </div>
              <div flexLayout="row">
                  <div fxFlex="10%">Posizione logo nei referti: </div>
                  <div fxFlex="90%">
                    <mat-form-field fxFlexFill>
                      <mat-select matInput [(ngModel)]="cs.posizionelogo" name="cs.posizionelogo">
                        <mat-option value="sinistra">Allineato a sinistra</mat-option>
                        <mat-option value="centro">Centralizzato</mat-option>
                        <mat-option value="destra">Allineato a destra</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
                
              <button mat-raised-button color="primary" (click)="SalvaCS()">Salva impostazioni per questo centro</button>
          </mat-tab>
          <mat-tab label="Medicina 2000 s.r.l.">
              <br/>
              <div flexLayout="row">
                <div fxFlex="10%">path del logo: </div>
                <div fxFlex="90%">
                  <mat-form-field fxFlexFill>
                    <input matInput [(ngModel)]="m2.logo" name="m2.logo">
                  </mat-form-field>
                </div>
              </div>
              <div flexLayout="row">
                <div fxFlex="10%">Denominazione: </div>
                <div fxFlex="90%">
                  <mat-form-field fxFlexFill>
                    <input matInput [(ngModel)]="m2.denominazione" name="m2.denominazione">
                  </mat-form-field>
                </div>
              </div>
              <div flexLayout="row">
                <div fxFlex="10%">Indirizzo: </div>
                <div fxFlex="90%">
                  <mat-form-field fxFlexFill>
                    <input matInput [(ngModel)]="m2.indirizzo" name="m2.indirizzo">
                  </mat-form-field>
                </div>
              </div>
              <div flexLayout="row">
                <div fxFlex="10%">Accreditamento N.: </div>
                <div fxFlex="90%">
                  <mat-form-field fxFlexFill>
                    <input matInput [(ngModel)]="m2.accreditamento" name="m2.accreditamento">
                  </mat-form-field>
                </div>
              </div>
              <div flexLayout="row">
                  <div fxFlex="10%">P.IVA: </div>
                  <div fxFlex="90%">
                    <mat-form-field fxFlexFill>
                      <input matInput [(ngModel)]="m2.piva" name="m2.piva">
                    </mat-form-field>
                  </div>
                </div>
              <div flexLayout="row">
                <div fxFlex="10%">Direttore Sanitario: </div>
                <div fxFlex="90%">
                  <mat-form-field fxFlexFill>
                    <input matInput [(ngModel)]="m2.direttoresanitario" name="m2.direttoresanitario">
                  </mat-form-field>
                </div>
              </div>
              <div flexLayout="row">
                <div fxFlex="10%">N.ro di telefono: </div>
                <div fxFlex="90%">
                  <mat-form-field fxFlexFill>
                    <input matInput [(ngModel)]="m2.telefono" name="m2.telefono">
                  </mat-form-field>
                </div>
              </div>
              <div flexLayout="row">
                <div fxFlex="10%">E-mail: </div>
                <div fxFlex="90%">
                  <mat-form-field fxFlexFill>
                    <input matInput [(ngModel)]="m2.email" name="m2.email">
                  </mat-form-field>
                </div>
              </div>
              <div flexLayout="row">
                <div fxFlex="10%">Sito Web: </div>
                <div fxFlex="90%">
                  <mat-form-field fxFlexFill>
                    <input matInput [(ngModel)]="m2.sito" name="m2.sito">
                  </mat-form-field>
                </div>
              </div>
              <div flexLayout="row">
                  <div fxFlex="10%">Posizione logo nei referti: </div>
                  <div fxFlex="90%">
                    <mat-form-field fxFlexFill>
                      <mat-select matInput [(ngModel)]="m2.posizionelogo" name="m2.posizionelogo">
                        <mat-option value="sinistra">Allineato a sinistra</mat-option>
                        <mat-option value="centro">Centralizzato</mat-option>
                        <mat-option value="destra">Allineato a destra</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              <button mat-raised-button color="primary" (click)="SalvaM2()">Salva impostazioni per questo centro</button>
          </mat-tab>
          <mat-tab label="Centro Diagnostico Cardiopolmonare s.r.l.">
            <br/>
            <div flexLayout="row">
              <div fxFlex="10%">path del logo: </div>
              <div fxFlex="90%">
                <mat-form-field fxFlexFill>
                  <input matInput [(ngModel)]="cdcp.logo" name="cdcp.logo">
                </mat-form-field>
              </div>
            </div>
            <div flexLayout="row">
              <div fxFlex="10%">Denominazione: </div>
              <div fxFlex="90%">
                <mat-form-field fxFlexFill>
                  <input matInput [(ngModel)]="cdcp.denominazione" name="cdcp.denominazione">
                </mat-form-field>
              </div>
            </div>
            <div flexLayout="row">
              <div fxFlex="10%">Indirizzo: </div>
              <div fxFlex="90%">
                <mat-form-field fxFlexFill>
                  <input matInput [(ngModel)]="cdcp.indirizzo" name="cdcp.indirizzo">
                </mat-form-field>
              </div>
            </div>
            <div flexLayout="row">
              <div fxFlex="10%">Accreditamento N.: </div>
              <div fxFlex="90%">
                <mat-form-field fxFlexFill>
                  <input matInput [(ngModel)]="cdcp.accreditamento" name="cdcp.accreditamento">
                </mat-form-field>
              </div>
            </div>
            <div flexLayout="row">
                <div fxFlex="10%">P.IVA: </div>
                <div fxFlex="90%">
                  <mat-form-field fxFlexFill>
                    <input matInput [(ngModel)]="cdcp.piva" name="cdcp.piva">
                  </mat-form-field>
                </div>
              </div>
            <div flexLayout="row">
              <div fxFlex="10%">Direttore Sanitario: </div>
              <div fxFlex="90%">
                <mat-form-field fxFlexFill>
                  <input matInput [(ngModel)]="cdcp.direttoresanitario" name="cdcp.direttoresanitario">
                </mat-form-field>
              </div>
            </div>
            <div flexLayout="row">
              <div fxFlex="10%">N.ro di telefono: </div>
              <div fxFlex="90%">
                <mat-form-field fxFlexFill>
                  <input matInput [(ngModel)]="cdcp.telefono" name="cdcp.telefono">
                </mat-form-field>
              </div>
            </div>
            <div flexLayout="row">
              <div fxFlex="10%">E-mail: </div>
              <div fxFlex="90%">
                <mat-form-field fxFlexFill>
                  <input matInput [(ngModel)]="cdcp.email" name="cdcp.email">
                </mat-form-field>
              </div>
            </div>
            <div flexLayout="row">
              <div fxFlex="10%">Sito Web: </div>
              <div fxFlex="90%">
                <mat-form-field fxFlexFill>
                  <input matInput [(ngModel)]="cdcp.sito" name="cdcp.sito">
                </mat-form-field>
              </div>
            </div>
            <div flexLayout="row">
                <div fxFlex="10%">Posizione logo nei referti: </div>
                <div fxFlex="90%">
                  <mat-form-field fxFlexFill>
                    <mat-select matInput [(ngModel)]="cdcp.posizionelogo" name="cdcp.posizionelogo">
                      <mat-option value="sinistra">Allineato a sinistra</mat-option>
                      <mat-option value="centro">Centralizzato</mat-option>
                      <mat-option value="destra">Allineato a destra</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
            <button mat-raised-button color="primary" (click)="SalvaCDCP()">Salva impostazioni per questo centro</button>
          </mat-tab>
          <mat-tab label="Listini prestazioni Operatori">
            <div flexLayout="row" fxLayout.xs="column">
              <div fxFlex="100%">
                <h3>Cardiologia</h3>
              </div>
            </div>
            <div flexLayout="row" fxLayout.xs="column">
              <div fxFlex="20%">
                <mat-form-field fxFlexFill>
                  <input placeholder="Visita ed ECG" matInput [(ngModel)]="listino.visita_ecg" name="listino.visita_ecg">
                  <mat-icon matSuffix >€</mat-icon>
                </mat-form-field>
              </div>
              <div fxFlex="20%">
                <mat-form-field fxFlexFill>
                  <input placeholder="Controllo" matInput [(ngModel)]="listino.controllo" name="listino.controllo">
                  <mat-icon matSuffix >€</mat-icon>
                </mat-form-field>
              </div>
              <div fxFlex="20%">
                <mat-form-field fxFlexFill>
                  <input placeholder="Controllo con ECG" matInput [(ngModel)]="listino.controllo_ecg" name="listino.controllo_ecg">
                  <mat-icon matSuffix >€</mat-icon>
                </mat-form-field>
              </div>
              <div fxFlex="20%">
                <mat-form-field fxFlexFill>
                  <input placeholder="ECG" matInput [(ngModel)]="listino.ecg" name="listino.ecg">
                  <mat-icon matSuffix >€</mat-icon>
                </mat-form-field>
              </div>
              <div fxFlex="20%">
                <mat-form-field fxFlexFill>
                  <input placeholder="Visita Priority" matInput [(ngModel)]="listino.visita_priority" name="listino.visita_priority">
                  <mat-icon matSuffix >€</mat-icon>
                </mat-form-field>
              </div>
            </div>
            <div flexLayout="row" fxLayout.xs="column">
              <div fxFlex="20%">
                <mat-form-field fxFlexFill>
                  <input placeholder="Prima Visita Priority" matInput [(ngModel)]="listino.prima_visita_priority" name="listino.prima_visita_priority">
                  <mat-icon matSuffix >€</mat-icon>
                </mat-form-field>
              </div>
              <div fxFlex="20%">
                <mat-form-field fxFlexFill>
                  <input placeholder="Ecocardiogramma" matInput [(ngModel)]="listino.ecocardiogramma" name="listino.ecocardiogramma">
                  <mat-icon matSuffix >€</mat-icon>
                </mat-form-field>
              </div>
              <div fxFlex="20%">
                <mat-form-field fxFlexFill>
                  <input placeholder="TSA" matInput [(ngModel)]="listino.tsa" name="listino.tsa">
                  <mat-icon matSuffix >€</mat-icon>
                </mat-form-field>
              </div>
              <div fxFlex="20%">
                <mat-form-field fxFlexFill>
                  <input placeholder="Doppler" matInput [(ngModel)]="listino.doppler" name="listino.doppler">
                  <mat-icon matSuffix >€</mat-icon>
                </mat-form-field>
              </div>
              <div fxFlex="20%">
                <mat-form-field fxFlexFill>
                  <input placeholder="Test da Sforzo" matInput [(ngModel)]="listino.test_sforzo" name="listino.test_sforzo">
                  <mat-icon matSuffix >€</mat-icon>
                </mat-form-field>
              </div>
            </div>
            <div flexLayout="row" fxLayout.xs="column">
              <div fxFlex="100%">
                <h3>Pneumologia</h3>
              </div>
            </div>
            <div flexLayout="row" fxLayout.xs="column">
              <div fxFlex="20%">
                <mat-form-field fxFlexFill>
                  <input placeholder="Visita" matInput [(ngModel)]="listino.visita_p" name="listino.visita_p">
                  <mat-icon matSuffix >€</mat-icon>
                </mat-form-field>
              </div>
              <div fxFlex="20%">
                <mat-form-field fxFlexFill>
                  <input placeholder="Controllo" matInput [(ngModel)]="listino.controllo_p" name="listino.controllo_p">
                  <mat-icon matSuffix >€</mat-icon>
                </mat-form-field>
              </div>
              <div fxFlex="20%">
                <mat-form-field fxFlexFill>
                  <input placeholder="Visita Privata" matInput [(ngModel)]="listino.visita_privata" name="listino.visita_privata">
                  <mat-icon matSuffix >€</mat-icon>
                </mat-form-field>
              </div>
              <div fxFlex="20%">
                <mat-form-field fxFlexFill>
                  <input placeholder="Spirometria Semplice" matInput [(ngModel)]="listino.spiro_semplice" name="listino.spiro_semplice">
                  <mat-icon matSuffix >€</mat-icon>
                </mat-form-field>
              </div>
              <div fxFlex="20%">
                <mat-form-field fxFlexFill>
                  <input placeholder="Spirometri Globale" matInput [(ngModel)]="listino.spiro_globale" name="listino.spiro_globale">
                  <mat-icon matSuffix >€</mat-icon>
                </mat-form-field>
              </div>
            </div>
            <div flexLayout="row" fxLayout.xs="column">
              <div fxFlex="20%">
                <mat-form-field fxFlexFill>
                  <input placeholder="Test di Broncodilatazione" matInput [(ngModel)]="listino.spiro_basale" name="listino.spiro_basale">
                  <mat-icon matSuffix >€</mat-icon>
                </mat-form-field>
              </div>
              <div fxFlex="20%">
                <mat-form-field fxFlexFill>
                  <input placeholder="Spirometria Completa" matInput [(ngModel)]="listino.spiro_completa" name="listino.spiro_completa">
                  <mat-icon matSuffix >€</mat-icon>
                </mat-form-field>
              </div>
              <div fxFlex="20%">
                <mat-form-field fxFlexFill>
                  <input placeholder="Visita e Semplice" matInput [(ngModel)]="listino.visita_spiro_semplice" name="listino.visita_spiro_semplice">
                  <mat-icon matSuffix >€</mat-icon>
                </mat-form-field>
              </div>
              <div fxFlex="20%">
                <mat-form-field fxFlexFill>
                  <input placeholder="Visita e Globale" matInput [(ngModel)]="listino.visita_spiro_globale" name="listino.visita_spiro_globale">
                  <mat-icon matSuffix >€</mat-icon>
                </mat-form-field>
              </div>
              <div fxFlex="20%">
                <mat-form-field fxFlexFill>
                  <input placeholder="Visita e Basale" matInput [(ngModel)]="listino.visita_spiro_basale" name="listino.visita_spiro_basale">
                  <mat-icon matSuffix >€</mat-icon>
                </mat-form-field>
              </div>
            </div>
            <div flexLayout="row" fxLayout.xs="column">
              <div fxFlex="20%">
                <mat-form-field fxFlexFill>
                  <input placeholder="Visita e Completa" matInput [(ngModel)]="listino.visita_spiro_completa" name="listino.visita_spiro_completa">
                  <mat-icon matSuffix >€</mat-icon>
                </mat-form-field>
              </div>
              <div fxFlex="20%">
                <mat-form-field fxFlexFill>
                  <input placeholder="Controllo e Semplice" matInput [(ngModel)]="listino.controllo_spiro_semplice" name="listino.controllo_spiro_semplice">
                  <mat-icon matSuffix >€</mat-icon>
                </mat-form-field>
              </div>
              <div fxFlex="20%">
                <mat-form-field fxFlexFill>
                  <input placeholder="Controllo e Globale" matInput [(ngModel)]="listino.controllo_spiro_globale" name="listino.controllo_spiro_globale">
                  <mat-icon matSuffix >€</mat-icon>
                </mat-form-field>
              </div>
              <div fxFlex="20%">
                <mat-form-field fxFlexFill>
                  <input placeholder="Controllo e Basale" matInput [(ngModel)]="listino.controllo_spiro_basale" name="listino.controllo_spiro_basale">
                  <mat-icon matSuffix >€</mat-icon>
                </mat-form-field>
              </div>
              <div fxFlex="20%">
                <mat-form-field fxFlexFill>
                  <input placeholder="Controllo e Completa" matInput [(ngModel)]="listino.controllo_spiro_completa" name="listino.controllo_spiro_completa">
                  <mat-icon matSuffix >€</mat-icon>
                </mat-form-field>
              </div>
            </div>
            <div flexLayout="row" fxLayout.xs="column">
              <div fxFlex="20%">
                <mat-form-field fxFlexFill>
                  <input placeholder="Test del Cammino" matInput [(ngModel)]="listino.test_cammino" name="listino.test_cammino">
                  <mat-icon matSuffix >€</mat-icon>
                </mat-form-field>
              </div>
              <div fxFlex="20%">
                <mat-form-field fxFlexFill>
                  <input placeholder="Polisonnografia" matInput [(ngModel)]="listino.polisonnografia" name="listino.polisonnografia">
                  <mat-icon matSuffix >€</mat-icon>
                </mat-form-field>
              </div>
              <div fxFlex="20%">
                <mat-form-field fxFlexFill>
                  <input placeholder="Prick Test" matInput [(ngModel)]="listino.prick_test" name="listino.prick_test">
                  <mat-icon matSuffix >€</mat-icon>
                </mat-form-field>
              </div>
              <div fxFlex="20%">
                <mat-form-field fxFlexFill>
                  <input placeholder="Prescrizione Fisioterapia" matInput [(ngModel)]="listino.prpn" name="listino.prpn">
                  <mat-icon matSuffix >€</mat-icon>
                </mat-form-field>
              </div>
              <div fxFlex="20%">
              </div>
            </div>
            <div flexLayout="row" fxLayout.xs="column">
              <div fxFlex="100%">
                <h3>Fisiatria</h3>
              </div>
            </div>
            <div flexLayout="row" fxLayout.xs="column">
              <div fxFlex="20%">
                <mat-form-field fxFlexFill>
                  <input placeholder="P.R.I." matInput [(ngModel)]="listino.pri" name="listino.pri">
                  <mat-icon matSuffix >€</mat-icon>
                </mat-form-field>
              </div>
              <div fxFlex="20%">
                <mat-form-field fxFlexFill>
                  <input placeholder="Baropodometria/Biometria" matInput [(ngModel)]="listino.bpe" name="listino.bpe">
                  <mat-icon matSuffix >€</mat-icon>
                </mat-form-field>
              </div>
              <div fxFlex="20%">
                <mat-form-field fxFlexFill>
                  <input placeholder="Esito Inail" matInput [(ngModel)]="listino.esito_inail" name="listino.esito_inail">
                  <mat-icon matSuffix >€</mat-icon>
                </mat-form-field>
              </div>
              </div>
            <button mat-raised-button color="primary" (click)="SalvaListino()">Salva Listino</button>
          </mat-tab>
        </mat-tab-group>
  </mat-card-content>
</mat-card>