<mat-card>
  <mat-card-title>Statistiche</mat-card-title>
  <mat-card-content>
  <mat-tab-group>
    <mat-tab label="Terapisti">
     

      <div fxLayout="row">
          <mat-form-field>
            <input matInput type="date" placeholder="Data inizio selezione" [(ngModel)]="form_presenza.data_in">
          </mat-form-field>
          &nbsp;
          <mat-form-field>
              <input matInput type="date" placeholder="Data fine selezione" [(ngModel)]="form_presenza.data_fin">
          </mat-form-field>
          &nbsp;
          <button mat-button color="primary" (click)="Cerca()">INVIO</button>
      </div>
    <mat-card>
      <mat-card-title>Statistiche operatori</mat-card-title>
      <mat-card-content>
          <div class="example-container mat-elevation-z8">
              <mat-table #table [dataSource]="dataSource">
            
                <!--- Note that these columns can be defined in any order.
                      The actual rendered columns are set as a property on the row definition" -->
            
                <ng-container matColumnDef="operatore">
                  <mat-header-cell *matHeaderCellDef> Operatore </mat-header-cell>
                  <mat-cell *matCellDef="let element"> {{element.name}} </mat-cell>
                </ng-container>
            
                <ng-container matColumnDef="prestazioni">
                  <mat-header-cell *matHeaderCellDef> Totale Prestazioni </mat-header-cell>
                  <mat-cell *matCellDef="let element"> {{element.value}}/{{numero_terapie}}({{(element.value/numero_terapie)*100 | number:'2.1-2'}}%) </mat-cell>
                </ng-container>
            
                <ng-container matColumnDef="totale">
                  <mat-header-cell *matHeaderCellDef> Tempo di Lavoro </mat-header-cell>
                  <mat-cell *matCellDef="let element"> {{element.totale}} </mat-cell>
                </ng-container>
            
                <ng-container matColumnDef="media">
                  <mat-header-cell *matHeaderCellDef> Media prestazioni oraria </mat-header-cell>
                  <mat-cell *matCellDef="let element"> {{element.value/element.ore | number:'2.1-2'}} </mat-cell>
                </ng-container>
            
                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
              </mat-table>
          </div>
          <div id="data2" class="example-container mat-elevation-z8">
              <mat-table #table [dataSource]="dataSource2">

                <ng-container matColumnDef="giorno">
                  <mat-header-cell *matHeaderCellDef> Giorno </mat-header-cell>
                  <mat-cell *matCellDef="let elements"> {{elements.data}} </mat-cell>
                </ng-container>
            
                <ng-container matColumnDef="dettaglio">
                  <mat-header-cell *matHeaderCellDef> Dettaglio Prestazioni </mat-header-cell>
                  <mat-cell *matCellDef="let elements">
                    <div flexLayout="row">
                      <div fxFlex="100%">
                        <div class="padding-bottom" flexLayout="row" *ngFor="let elemento of elements.value">
                          <div fxFlex="10%"><b>{{elemento.username}}</b></div>
                          <div fxFlex="20%">Tempo totale: {{elemento.totale}}<br/>Media prestazioni giorno: {{elemento.media | number:'2.1-2'}}</div>
                          <div fxFlex="70%" class="padding-cell">
                            <div flexLayout="row"><div fxFlex="20%" *ngFor="let dato of (elemento.terapie ? elemento.terapie.slice(0,10):[])"><b>{{dato.cognome}}</b><br/><i>{{dato.timestamp | date: 'HH:mm'}}</i><br/>{{dato.descrizione}}</div></div>
                            <div flexLayout="row"><div fxFlex="20%" *ngFor="let dato of (elemento.terapie ? elemento.terapie.slice(10,20):[])"><b>{{dato.cognome}}</b><br/><i>{{dato.timestamp | date: 'HH:mm'}}</i><br/>{{dato.descrizione}}</div></div>
                            <div flexLayout="row"><div fxFlex="20%" *ngFor="let dato of (elemento.terapie ? elemento.terapie.slice(20,30):[])"><b>{{dato.cognome}}</b><br/><i>{{dato.timestamp | date: 'HH:mm'}}</i><br/>{{dato.descrizione}}</div></div>
                            <div flexLayout="row"><div fxFlex="20%" *ngFor="let dato of (elemento.terapie ? elemento.terapie.slice(30,40):[])"><b>{{dato.cognome}}</b><br/><i>{{dato.timestamp | date: 'HH:mm'}}</i><br/>{{dato.descrizione}}</div></div>
                            <div flexLayout="row"><div fxFlex="20%" *ngFor="let dato of (elemento.terapie ? elemento.terapie.slice(40,50):[])"><b>{{dato.cognome}}</b><br/><i>{{dato.timestamp | date: 'HH:mm'}}</i><br/>{{dato.descrizione}}</div></div>
                            <div flexLayout="row"><div fxFlex="20%" *ngFor="let dato of (elemento.terapie ? elemento.terapie.slice(50,60):[])"><b>{{dato.cognome}}</b><br/><i>{{dato.timestamp | date: 'HH:mm'}}</i><br/>{{dato.descrizione}}</div></div>
                            <div flexLayout="row"><div fxFlex="20%" *ngFor="let dato of (elemento.terapie ? elemento.terapie.slice(60,70):[])"><b>{{dato.cognome}}</b><br/><i>{{dato.timestamp | date: 'HH:mm'}}</i><br/>{{dato.descrizione}}</div></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </mat-cell>
                </ng-container>
            
                <mat-header-row *matHeaderRowDef="displayedColumns2"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns2;"></mat-row>
              </mat-table>
          </div>
             
      </mat-card-content>
    </mat-card>
  
   
  </mat-tab>
  <mat-tab label="Prestazioni">
    <div fxLayout="row">
      <mat-form-field>
        <input matInput type="date" placeholder="Data inizio selezione" [(ngModel)]="form_presenza.data_in">
      </mat-form-field>
      &nbsp;
      <mat-form-field>
          <input matInput type="date" placeholder="Data fine selezione" [(ngModel)]="form_presenza.data_fin">
      </mat-form-field>
      &nbsp;
      <button mat-button color="primary" (click)="CercaPrestazioni()">INVIO</button>
  </div>
  <mat-card *ngIf="array_stats">
    <mat-card-title>Riepilogo</mat-card-title>
    <mat-card-content>
      <div flexLayout="row" class="padding-bottom-prest">
        <h5>Pneumologia</h5>
      </div>
      <div flexLayout="row" class="padding-bottom-prest">
        <div fxFlex="20%">Spirometrie eseguite:</div>
        <div fxFlex="80%">{{array_stats[0].spirometrie}} - di cui {{array_stats[14].semplice}} Semplici, {{array_stats[15].globale}} Globali, {{array_stats[16].completa}} Complete, {{array_stats[17].basale}} Basali con broncodilatazione</div>
      </div>
      <div flexLayout="row" class="padding-bottom-prest">
        <div fxFlex="20%">Visite pneumologiche eseguite:</div>
        <div fxFlex="80%">{{array_stats[1].visitepneumo}} - di cui {{array_stats[18].controllopneumo}} Visite di controllo</div>
      </div>
      <div flexLayout="row" class="padding-bottom-prest">
        <div fxFlex="20%">Test del Cammino eseguiti:</div>
        <div fxFlex="80%">{{array_stats[2].testcammino}}</div>
      </div>
      <div flexLayout="row" class="padding-bottom-prest">
        <h5>Cardiologia</h5>
      </div>
      <div flexLayout="row" class="padding-bottom-prest">
        <div fxFlex="20%">Visite Cardiologiche eseguite:</div>
        <div fxFlex="80%">{{array_stats[3].visitecardiologiche}} - di cui {{array_stats[19].controllocardio}} visite di controllo e {{array_stats[8].priority}} Priority</div>
      </div>
      <div flexLayout="row" class="padding-bottom-prest">
        <div fxFlex="20%">Ecografie eseguite:</div>
        <div fxFlex="80%">{{array_stats[4].ecografie}}</div>
      </div>
      <div flexLayout="row" class="padding-bottom-prest">
        <div fxFlex="20%">TSA eseguiti:</div>
        <div fxFlex="80%">{{array_stats[5].tsa}}</div>
      </div>
      <div flexLayout="row" class="padding-bottom-prest">
        <div fxFlex="20%">Doppler eseguiti:</div>
        <div fxFlex="80%">{{array_stats[6].doppler}}</div>
      </div>
      <div flexLayout="row" class="padding-bottom-prest">
        <div fxFlex="20%">Test da Sforzo eseguiti:</div>
        <div fxFlex="80%">{{array_stats[7].testsforzo}}</div>
      </div>
      <div flexLayout="row" class="padding-bottom-prest">
        <div fxFlex="20%">Holter eseguiti:</div>
        <div fxFlex="80%">{{array_stats[12].holter}}</div>
      </div>
      <div flexLayout="row" class="padding-bottom-prest">
        <div fxFlex="20%">Ecografie prescritte (cercate le parole 'ecografia','ecocolor','eco color','ecocardio' nel campo <i>Prescrizioni</i>):</div>
        <div fxFlex="80%">{{array_stats[10].eco_prescritte}}</div>
      </div>
      <div flexLayout="row" class="padding-bottom-prest">
        <div fxFlex="20%">Holter prescritti (cercate le parole 'holter', 'monitoraggio ambulatoriale' nel campo <i>Prescrizioni</i>):</div>
        <div fxFlex="80%">{{array_stats[11].holter_prescritti}}</div>
      </div>
      <div flexLayout="row" class="padding-bottom-prest">
        <h5>Fisiatria</h5>
      </div>
      <div flexLayout="row" class="padding-bottom-prest">
        <div fxFlex="20%">BPE eseguite:</div>
        <div fxFlex="80%">{{array_stats[9].bpe}}</div>
      </div>
      <div flexLayout="row" class="padding-bottom-prest">
        <div fxFlex="20%">Plantari ordinati:</div>
        <div fxFlex="80%">{{array_stats[13].plantari}}</div>
      </div>
    </mat-card-content>
  </mat-card>
  </mat-tab>
</mat-tab-group>
</mat-card-content>
</mat-card>
