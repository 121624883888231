<mat-card>
  <mat-card-title>{{titolo}}</mat-card-title>
  <mat-card-content>
      <div flexLayout="row">
          <div fxFlex="40%">
              <mat-form-field fxFlexFill>
                  <input matInput placeholder="Cognome" [(ngModel)]="paziente.cognome" name="paziente.cognome">
              </mat-form-field>
          </div>
          <div fxFlex="40%">
              <mat-form-field fxFlexFill>
                  <input matInput placeholder="Nome" [(ngModel)]="paziente.nome" name="paziente.nome">
              </mat-form-field>
          </div>
          <div fxFlex="20%">
            <mat-form-field fxFlexFill>
                <input matInput type="date" placeholder="Data di nascita" [(ngModel)]="paziente.data_nascita">
              </mat-form-field>
          </div>
      </div>
      <button  color="primary" mat-fab (click)="Save()"><mat-icon>save</mat-icon></button>
  </mat-card-content>
</mat-card>
