<mat-card>
  <mat-card-title>Registro Presenze - Nome Operatore: <span style="font-weight: 800">{{username}}</span></mat-card-title>
  <mat-card-content>
    <div *ngIf='uscita'>
      Hai timbrato l'ingresso alle ore: <span style="font-weight: 600; font-size: 150%">{{ingresso.ingresso}}</span><br/>
      <button mat-button class="mat-button" color="warn" (click)="Timbra()">TIMBRA USCITA</button>
    </div>
    <div *ngIf='!uscita'>
      Non hai timbrato l'ingresso per questa sessione di lavoro<br/>
      <button mat-button color="primary" (click)="Timbra()">TIMBRA INGRESSO</button>
    </div>
    
  </mat-card-content>
</mat-card>
