import { Component, OnInit } from '@angular/core';
//import { TdDataTableService, TdDataTableSortingOrder, ITdDataTableSortChangeEvent, ITdDataTableColumn, IPageChangeEvent } from '@covalent/core';
import {Form_Presenze, array_nomi, array_nomi_cardiologia,array_nomi_fisiatria,array_etichette_cardiologia,array_nomi_pneumologia, array_etichette_pneumologia} from '../_models';
import {GlobalServices} from '../_services';

@Component({
  selector: 'app-riepilogo-presenze-direttore',
  templateUrl: './riepilogo-presenze-direttore.component.html',
  styleUrls: ['./riepilogo-presenze-direttore.component.css']
})
export class RiepilogoPresenzeDirettoreComponent implements OnInit {

  form_presenza= new Form_Presenze;
  elenco_presenze= new Array<any>();
  elenco_visite= new Array<any>();
  totale_orario: string;
  totali= {};
  listino_personale;
  username;
  ore_agg;
  minuti_agg;
  lordo_meno;
  lordo_piu;
  remunerazione: number;
  ritenuta: number;
  rivalsa: number;
  somma_precisa;
  somma_arrotondata;
  ritenuta_precisa;
  rivalsa_precisa;
  ritenuta_arrotondata;
  totale_prestazioni: number;

  totale_preciso;
  totale_arrotondato;
  totale_preciso_minimi;
  totale_arrotondato_minimi;
  prestazioni_private= new Array<any>();
  marca_bollo_orario: number;
  marca_bollo_prestazioni: number;
  totale_lordo;
  rivalsa_inps_prestazioni;
  totale_netto;
  totale_netto_prestazioni;
  ritenuta_prestazioni;
  totale_netto_visual;

  pneumo_marca_bollo_prestazioni: number;
  pneumo_totale_lordo;
  pneumo_rivalsa_inps_prestazioni;
  pneumo_totale_netto_prestazioni;
  pneumo_ritenuta_prestazioni;
  pneumo_totale_netto_visual;
  pneumo_totale_preciso;
  pneumo_totale_arrotondato;
  pneumo_totale_preciso_minimi;
  pneumo_totale_arrotondato_minimi;
  pneumo_totale_netto;

  pneumo_lordo_visual: any;
  lordo_visual: number;
  array_nomi_fisiatria= new Array<any>();
  array_nomi_pneumo= new Array<any>();
  array_nomi_cardio= new Array<any>();
  array_prestazioni;
  array_nomi_visite;
  data_selezionata: any;
  anamnesi_selezionata: any;
  fisio_totale_lordo: number;
  fisio_lordo_visual: number;
  fisio_rivalsa_inps_prestazioni: number;
  fisio_ritenuta_prestazioni: number;
  fisio_totale_netto: number;
  fisio_totale_netto_visual: any;
  fisio_marca_bollo_prestazioni: number;

  constructor(public service: GlobalServices) { }

  ngOnInit() {
    this.marca_bollo_orario=0;
    this.marca_bollo_prestazioni=0;
    this.pneumo_marca_bollo_prestazioni=0;
    this.remunerazione=0;
    this.ritenuta=0;
    this.rivalsa=0;
    this.service.get_function(this.service.apiGetUsername+'/login').subscribe(
      data=>this.username=data
    );
    this.service.get_function(this.service.apiGetTipiVisite).subscribe(
      data=>this.array_nomi_visite=data);
    this.ore_agg=0;
    this.minuti_agg=0;
    this.lordo_meno=0;
    this.lordo_piu=0;
  }

  Cerca(){
    this.marca_bollo_orario=0;
    this.marca_bollo_prestazioni=0;
    this.prestazioni_private= new Array<any>();
    this.array_nomi_visite.forEach(element=>{
      this.totali[element.tipo_visita]=0;
    });
    this.totale_lordo=0;
    this.pneumo_totale_lordo=0;
    this.fisio_totale_lordo=0;
    this.service.post_function_bis(JSON.stringify(this.form_presenza),this.service.apiGetRiepilogo).subscribe(
      dati=>{
        this.elenco_presenze=dati.data;
        this.totale_orario=dati.totale;
        this.rivalsa=dati.posizione_fiscale.rivalsa;
        this.ritenuta=dati.posizione_fiscale.ritenuta;
        this.remunerazione=dati.posizione_fiscale.remunerazione_oraria;
        this.listino_personale=JSON.parse(dati.posizione_fiscale.listino_personale);
        if(dati.prestazioni_private) this.prestazioni_private=dati.prestazioni_private;
        this.array_nomi_fisiatria= new Array<any>();
        this.array_nomi_pneumo= new Array<any>();
        this.array_nomi_cardio= new Array<any>();
        this.service.get_function(this.service.apiGetTipiVisite).subscribe(
          data=>
          {
            //this.array_prestazioni=data;
            data.forEach(element => {
              this.totali[element.tipo_visita]=0;
              switch(element.strutture_abilitate){
                case '1':
                  this.array_nomi_fisiatria.push(element.tipo_visita);
                  break;
                case '4':
                  this.array_nomi_pneumo.push(element.tipo_visita);
                  break;
                case '2':
                  if(element.tipo_visita!='Anamnesi')
                  this.array_nomi_cardio.push(element.tipo_visita);
                  break;
              }
              
            });
            this.Calcola_remunerazione();
        }
        );
        
      }
    );
    
  }

  ReturnNome(element){
    return this.array_nomi_visite.filter(el=>el.tipo_visita==element)[0].descrizione;
  }

  ReturnCosto(element){
    return this.listino_personale.filter(el=>el.tipo_visita==element)[0].costo;
  }

  Calcola_remunerazione(){
    let numeri= this.totale_orario.split(':');
    let hour=parseInt(numeri[0])+this.ore_agg;
    let minutes=parseInt(numeri[1])+this.minuti_agg;
    let seconds=parseInt(numeri[2]);
    this.somma_precisa=((this.remunerazione*hour)+Math.round(this.remunerazione*(minutes/60))+(this.remunerazione*(seconds/3600))-this.lordo_meno+this.lordo_piu).toFixed(2);
    this.rivalsa_precisa=(this.somma_precisa*this.rivalsa/100).toFixed(2);
    this.ritenuta_precisa=((parseFloat(this.somma_precisa)+parseFloat(this.rivalsa_precisa))*this.ritenuta/100).toFixed(2);    
    this.totale_preciso=(parseFloat(this.somma_precisa)-parseFloat(this.ritenuta_precisa)+parseFloat(this.rivalsa_precisa)).toFixed(2);
    if(this.totale_preciso>77.47) {
      this.totale_preciso=(parseFloat(this.somma_precisa)-parseFloat(this.ritenuta_precisa)+parseFloat(this.rivalsa_precisa)+2).toFixed(2);
      this.marca_bollo_orario=2;
    }

    //CALCOLO PER PRESTAZIONI
    this.service.post_function(JSON.stringify(this.form_presenza),this.service.apiGetRiepilogo+'prestazioni').subscribe(dati=>{
      this.elenco_visite=dati;
      //this.filteredData=dati;
      if(dati)this.totale_prestazioni=dati.length;
      if(dati)for(var i=0; i<this.elenco_visite.length; i++){
        this.totali[this.elenco_visite[i].tipo]++;
      }

      //CARDIOLOGIA
      this.array_nomi_cardio.forEach(element=>{
        let cc=this.listino_personale.filter(el=>el.tipo_visita==element)[0].costo;
        this.totale_lordo+=this.totali[element]*cc;
      });
      this.totale_lordo=Math.floor(this.totale_lordo*100.00)/100.00;
      this.lordo_visual=Math.floor((this.totale_lordo-this.lordo_meno+this.lordo_piu)*100.00)/100.00;
      this.rivalsa_inps_prestazioni=Math.floor(((this.totale_lordo-this.lordo_meno+this.lordo_piu)*this.rivalsa/100)*100.00)/100.00;
      this.ritenuta_prestazioni=Math.floor(((this.totale_lordo-this.lordo_meno+this.lordo_piu)*this.ritenuta/100)*100.00)/100.00;
      this.totale_netto=Math.floor((this.totale_lordo-this.ritenuta_prestazioni+this.rivalsa_inps_prestazioni-this.lordo_meno+this.lordo_piu)*100.00)/100.00;
      if(this.totale_netto>77.47) this.marca_bollo_prestazioni=2;
      this.totale_netto_visual=this.totale_netto+this.marca_bollo_prestazioni;

      //PNEUMOLOGIA
      this.array_nomi_pneumo.forEach(element=>{
        let cc=this.listino_personale.filter(el=>el.tipo_visita==element)[0].costo;
        this.pneumo_totale_lordo+=this.totali[element]*cc;
      });
      this.pneumo_totale_lordo=Math.floor(this.pneumo_totale_lordo*100.00)/100.00;
      this.pneumo_lordo_visual=Math.floor((this.pneumo_totale_lordo-this.lordo_meno+this.lordo_piu)*100.00)/100.00;
      //this.pneumo_totale_lordo=Math.floor(((this.totale_visitepneumologica*this.costo_visitapneumologica)+(this.totale_controllopneumo*this.costo_controllopneumo)+(this.totale_spirosemplice*this.costo_spirosemplice)+(this.totale_spiroglobale*this.costo_spiroglobale)+(this.totale_spirobasale*this.costo_spirobasale)+(this.totale_spirocompleta*this.costo_spirocompleta)+(this.totale_visitaesemplice*this.costo_visitaesemplice)+(this.totale_visitaeglobale*this.costo_visitaeglobale)+(this.totale_visitaebasale*this.costo_visitaebasale)+(this.totale_visitaecompleta*this.costo_visitaecompleta)+(this.totale_controlloesemplice*this.costo_controlloesemplice)+(this.totale_controlloeglobale*this.costo_controlloeglobale)+(this.totale_controlloebasale*this.costo_controlloebasale)+(this.totale_controlloecompleta*this.costo_controlloecompleta)+(this.totale_testcammino*this.costo_testcammino)+(this.totale_pricktest*this.costo_pricktest)+(this.totale_polisonnografia*this.costo_polisonnografia)+(this.totale_visitapneumoprivata*this.costo_visitapneumoprivata))*100.00)/100.00;
      this.pneumo_rivalsa_inps_prestazioni=Math.floor(((this.pneumo_totale_lordo-this.lordo_meno+this.lordo_piu)*this.rivalsa/100)*100.00)/100.00;
      this.pneumo_ritenuta_prestazioni=Math.floor(((this.pneumo_totale_lordo-this.lordo_meno+this.lordo_piu)*this.ritenuta/100)*100.00)/100.00;
      this.pneumo_totale_netto=Math.floor((this.pneumo_totale_lordo-this.pneumo_ritenuta_prestazioni+this.pneumo_rivalsa_inps_prestazioni-this.lordo_meno+this.lordo_piu)*100.00)/100.00;
      if(this.pneumo_totale_netto>77.47) this.pneumo_marca_bollo_prestazioni=2;
      this.pneumo_totale_netto_visual=this.pneumo_totale_netto+this.pneumo_marca_bollo_prestazioni;

      //FISIATRIA
      this.array_nomi_fisiatria.forEach(element=>{
        let cc=this.listino_personale.filter(el=>el.tipo_visita==element)[0].costo;
        this.fisio_totale_lordo+=this.totali[element]*cc;
      });
      this.fisio_totale_lordo=Math.floor(this.fisio_totale_lordo*100.00)/100.00;
      this.fisio_lordo_visual=Math.floor((this.fisio_totale_lordo-this.lordo_meno+this.lordo_piu)*100.00)/100.00;
      //this.pneumo_totale_lordo=Math.floor(((this.totale_visitepneumologica*this.costo_visitapneumologica)+(this.totale_controllopneumo*this.costo_controllopneumo)+(this.totale_spirosemplice*this.costo_spirosemplice)+(this.totale_spiroglobale*this.costo_spiroglobale)+(this.totale_spirobasale*this.costo_spirobasale)+(this.totale_spirocompleta*this.costo_spirocompleta)+(this.totale_visitaesemplice*this.costo_visitaesemplice)+(this.totale_visitaeglobale*this.costo_visitaeglobale)+(this.totale_visitaebasale*this.costo_visitaebasale)+(this.totale_visitaecompleta*this.costo_visitaecompleta)+(this.totale_controlloesemplice*this.costo_controlloesemplice)+(this.totale_controlloeglobale*this.costo_controlloeglobale)+(this.totale_controlloebasale*this.costo_controlloebasale)+(this.totale_controlloecompleta*this.costo_controlloecompleta)+(this.totale_testcammino*this.costo_testcammino)+(this.totale_pricktest*this.costo_pricktest)+(this.totale_polisonnografia*this.costo_polisonnografia)+(this.totale_visitapneumoprivata*this.costo_visitapneumoprivata))*100.00)/100.00;
      this.fisio_rivalsa_inps_prestazioni=Math.floor(((this.fisio_totale_lordo-this.lordo_meno+this.lordo_piu)*this.rivalsa/100)*100.00)/100.00;
      this.fisio_ritenuta_prestazioni=Math.floor(((this.fisio_totale_lordo-this.lordo_meno+this.lordo_piu)*this.ritenuta/100)*100.00)/100.00;
      this.fisio_totale_netto=Math.floor((this.fisio_totale_lordo-this.fisio_ritenuta_prestazioni+this.fisio_rivalsa_inps_prestazioni-this.lordo_meno+this.lordo_piu)*100.00)/100.00;
      if(this.fisio_totale_netto>77.47) this.fisio_marca_bollo_prestazioni=2;
      this.fisio_totale_netto_visual=this.fisio_totale_netto+this.fisio_marca_bollo_prestazioni;
    });
  }

  showInfo(obj,stampa)
  {
    let tipo;
    switch(obj.tipo){
      case 'VisitaControllo':
      case 'VisitaPriority':
      case 'PrimaVisitaPriority':
      case 'ControlloConEcg':
      case 'ECG':
      case 'Visita':
            tipo="visitaC";
            break;
      case 'Visita_di_controllo':
        case 'Visita_privata':
        case 'Visita_+_Sp.Globale':
        case 'Visita_+_Sp.Completa': 
        case 'Visita_+_Sp.Semplice':
        case 'Visita_+_Sp.Basale_con_Broncodilatazione':
        case 'Sp.Globale':
        case 'Sp.Semplice':
        case 'Sp.Basale_con_Broncodilatazione':
        case 'Sp.Completa':
        case 'Controllo_con_Sp.Semplice':
        case 'Controllo_con_Sp.Globale':
        case 'Controllo_con_Sp.Completa':
        case 'Controllo_con_Sp.Basale_con_Broncodilatazione':
        case 'Polisonnografia':
        case 'Sp.Semplice_e_Broncodilatazione':
        case 'Sp.Globale_e_Broncodilatazione':
        case 'dlco':
        case 'VisitaPneumo':
              tipo='VisitaPneumo';
              break;
        case 'DopplerArtiDouble':
          tipo='DOPPLERDOUBLE';
          break;
        case 'DopplerArti':
          tipo='DOPPLER';
          break;
      default:
        tipo=obj.tipo;
        break;
    }

    let paziente_selezionato={
      cognome: obj.cognome, nome: obj.nome, data_nascita: obj.data_nascita
    }
    this.data_selezionata=obj.data;
    this.service.get_function(this.service.apiGetAnamnesi+obj.branca+'/'+obj.data+'/'+obj.idpaziente).subscribe(data=>
      {
        if(data) this.anamnesi_selezionata=JSON.parse(data[0].valore);
           this.service.print(tipo,JSON.parse(obj.valore),this.data_selezionata,paziente_selezionato,obj.nominativo,this.anamnesi_selezionata,obj.branca,stampa,'');
      }
      )
    
  }

}
