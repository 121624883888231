import { Component } from '@angular/core';
import { first } from 'rxjs/operators';

import { Utenti } from '../_models';
import { UserService, AuthenticationService, GlobalServices } from '../_services';
import {Router} from '@angular/router';

@Component({ templateUrl: 'home.component.html',styleUrls: ['./home.component.scss'] })
export class HomeComponent {
    loading = false;
    users: Utenti[];
    over: any;
    flex_items;
    Welcome = sessionStorage.getItem('username');
    access_level: number;
        
    admin_menu: Object[] = [{
      title: 'Bacheca',
      route: '/',
      icon: 'dashboard'
    },
    {
      title: 'Pazienti',
      route: '/archivio',
      icon: 'contacts'
    },
    {
      title: 'Certificati',
      route: '/matrici',
      icon: 'crop_portrait'
    },
    {
      title: 'Holter / PSG',
      route: '/holter',
      icon: 'healing',
      svg:'holter'
    },
    {
      title: 'Plantari',
      route: '/plantari',
      icon: 'add',
      svg:'orme'
    },
    {
      title: 'Polisonnografie',
      route: '/polisonnografia',
      icon: 'hotel'
    },
    {
      title: 'Riepilogo Presenze',
      route: '/riepilogo-presenze',
      icon: 'history'
    },{
      title: 'Statistiche',
      route: '/statistiche',
      icon: 'show_chart'
    },
    {
      title: 'Gestione Utenti',
      route: '/amministrazione-utenti',
      icon: 'supervisor_account'
    },

    {
      title: 'Impostazioni',
      route: '/impostazioni',
      icon: 'settings'
    }


    ];

    segreteria_menu: Object[] = [{
    title: 'Bacheca',
    route: '/',
    icon: 'dashboard'
    },
    {
    title: 'Pazienti',
    route: '/archivio',
    icon: 'contacts'
    },
    {
    title: 'Certificati',
    route: '/matrici',
    icon: 'crop_portrait'
    },
    {
    title: 'Plantari',
    route: '/plantari',
    icon: 'add',
    svg:'orme'
    },
    {
      title: 'Polisonnografie',
      route: '/polisonnografia',
      icon: 'hotel'
    },

    ];

    riepilogo_menu: Object[] = [{
    title: 'Bacheca',
    route: '/',
    icon: 'dashboard'
    }

    ];

    collaboratore_menu: Object[] = [{
    title: 'Bacheca',
    route: '/',
    icon: 'dashboard'
    },
    {
    title: 'Timbra',
    route: '/timbra',
    icon: 'history'
    },
    {
    title: 'Schede',
    route: '/schede',
    icon: 'tab'
    }

    ];

    collaboratore_polisonnografie_menu: Object[] = [{
      title: 'Bacheca',
      route: '/',
      icon: 'dashboard'
      },
      {
      title: 'Polisonnografie',
      route: '/polisonnografia',
      icon: 'hotel'
      }  
      ];

    terapista_menu: Object[] = [{
    title: 'Bacheca',
    route: '/',
    icon: 'dashboard',
    },
    /*{
    title: 'Timbra',
    route: '/timbra',
    icon: 'history'
    },*/
    {
    title: 'Schede',
    route: '/archivio',
    icon: 'tab'
    }
    ];

    segreteria_cdcp_menu: Object[] = [{
    title: 'Bacheca',
    route: '/',
    icon: 'dashboard',
    },
    {
    title: 'Timbra',
    route: '/timbra',
    icon: 'history'
    },
    {
    title: 'Pazienti',
    route: '/archivio',
    icon: 'contacts'
    },
    {
    title: 'Certificati',
    route: '/matrici',
    icon: 'crop_portrait'
    },
    {
    title: 'Holter / PSG',
    route: '/holter',
    icon: 'healing',
    svg:'holter'
    }
    ];

    infermiera_cdcp_menu: Object[] = [{
    title: 'Bacheca',
    route: '/',
    icon: 'dashboard',
    },
    {
    title: 'Timbra',
    route: '/timbra',
    icon: 'history'
    },
    {
    title: 'Pazienti',
    route: '/archivio',
    icon: 'contacts'
    },
    {
    title: 'Holter / PSG',
    route: '/holter',
    icon: 'healing',
    svg:'holter'
    }
    ];

    cardiologo_cdcp_menu: Object[] = [{
    title: 'Bacheca',
    route: '/',
    icon: 'dashboard',
    },
    {
    title: 'Timbra',
    route: '/timbra',
    icon: 'history'
    },
    {
    title: 'Pazienti',
    route: '/archivio',
    icon: 'contacts'
    }
    ];

    pneumologo_menu: Object[] = [{
    title: 'Bacheca',
    route: '/',
    icon: 'dashboard',
    },
    {
    title: 'Timbra',
    route: '/timbra',
    icon: 'history'
    },
    {
    title: 'Pazienti',
    route: '/archivio',
    icon: 'contacts'
    }
    ];

    fisiatra_menu: Object[] = [{
    title: 'Bacheca',
    route: '/',
    icon: 'dashboard',
    },
    {
    title: 'Timbra',
    route: '/timbra',
    icon: 'history'
    },
    {
    title: 'Pazienti',
    route: '/archivio',
    icon: 'contacts'
    }
    ];
    all_menu=[this.admin_menu, this.segreteria_menu,this.terapista_menu,this.collaboratore_menu,this.riepilogo_menu,this.segreteria_cdcp_menu,this.infermiera_cdcp_menu,this.cardiologo_cdcp_menu,this.fisiatra_menu,this.pneumologo_menu, this.collaboratore_polisonnografie_menu ];
menu;
data_now=new Date();

    constructor(private userService: UserService, private _authService: AuthenticationService, private router: Router, private service: GlobalServices) { 
      this.access_level=service.get_access_level();
      this.menu=this.all_menu[this.access_level-1];
    }

    ngOnInit() {
      
      this.flex_items=100/(this.menu.length+1);
     
    }

    logout(){
      this._authService.logout();
      this.router.navigate(['/login']);
    }
}