<mat-card>
  <mat-card-title>
    <span *ngIf="!utente.idutente"> Aggiungi Utente</span>
    <span *ngIf="utente.idutente"> Modifica Utente</span>
  </mat-card-title>
  <mat-divider></mat-divider>
  <br/>
  <mat-card-content>
    <form #UtenteForm="ngForm" validate>
      <div flexLayout="row" fxLayoutGap="20px">
        <div fxFlex="50%">
            <mat-form-field fxFlexFill>
                <input matInput placeholder="Username" required [(ngModel)]="utente.username" name="utente.username" [disabled]="stato_update">
            </mat-form-field>
        </div>
        <div fxFlex="50%">
          <mat-form-field fxFlexFill>
              <input matInput placeholder="Nominativo" required [(ngModel)]="utente.nominativo" name="utente.nominativo">
          </mat-form-field>
      </div>
      </div>
      <br/>
      <div flexLayout="row" fxLayoutGap="20px">
        <div fxFlex="50%" >
          <mat-form-field>
            <input matInput type="date" placeholder="Data ingresso" name="data_in" [(ngModel)]="utente.data_in" required [disabled]="stato_update">
          </mat-form-field>
        </div>
        <div fxFlex="50%" >
            <mat-form-field fxFlexFill>
              <input matInput type="date" placeholder="Data Uscita" name="data_out" [(ngModel)]="utente.data_out">
              <mat-hint *ngIf="stato_update">Se non impostata lasciare la data 1/1/1900 </mat-hint>
            </mat-form-field>
        </div>
      </div>
      <br/>
      <div flexLayout="row" fxLayoutGap="20px">
          <div fxFlex="10%" >
            <button mat-raised-button color="primary" (click)="ChangePSW()">Modifica</button>
            <button mat-raised-button color="primary" (click)="makeid(6)">Genera PSW</button>
          </div>
          <div fxFlex="40%" >
              <mat-form-field fxFlexFill>
                  <input type="password" matInput placeholder="Password" [required]="!stato_update" [(ngModel)]="utente.password" name="utente.password" autocomplete="nope" [disabled]="change_password">
                  <mat-hint *ngIf="stato_update">Compilare solo nel caso in cui si voglia cambiare password </mat-hint>
                </mat-form-field>
                <span class="error-hint" *ngIf="stato_update && utente.password">ATTENZIONE: stai modificando la password per questo utente</span>
          </div>
          <div fxFlex="50%" >
              <mat-form-field fxFlexFill>
                  <mat-select placeholder="Ruolo" required [(ngModel)]="utente.ruolo" name="utente.ruolo" >
                      <mat-option *ngFor="let tipo of tipi" [value]="tipo.typeid">
                        {{tipo.tipo_utente}}
                      </mat-option>
                    </mat-select>
              </mat-form-field>
          </div>
        </div>
        <br/>
        <div flexLayout="row" fxLayoutGap="20px">
          <div fxFlex="15%">
            <mat-form-field fxFlexFill>
                <input matInput placeholder="E-mail" required [(ngModel)]="utente.email" name="utente.email">
            </mat-form-field>
        </div>
        <div fxFlex="15%">
          <mat-form-field fxFlexFill>
              <input matInput type="number" placeholder="Remunerazione Oraria" required [(ngModel)]="utente.remunerazione_oraria" name="utente.remunerazione_oraria">
          </mat-form-field>
      </div>
          <div fxFlex="10%">
              <mat-form-field fxFlexFill>
                  <input matInput type="number" placeholder="Rivalsa INPS %" required [(ngModel)]="utente.rivalsa" name="utente.rivalsa">
              </mat-form-field>
          </div>
          <div fxFlex="10%">
            <mat-form-field fxFlexFill>
                <input matInput type="number" placeholder="Ritenuta d'acconto" required [(ngModel)]="utente.ritenuta" name="utente.ritenuta">
            </mat-form-field>
        </div>
        <div fxFlex="15%">
          <mat-form-field fxFlexFill>
              <input matInput type="number" min=0 max=100 placeholder="Percentuale da applicare" required [(ngModel)]="utente.percentuale" name="utente.percentuale" (change)="CambiaListino()">
          </mat-form-field>
        </div>
        </div>
        <div fxLayout="row" fxLayout.xs="column">
          <div fxFlex="100%">
            <h2>Listino personale</h2>
          </div>
        </div>
        <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="10px">
          <div fxFlex="33%">
            <div fxLayout="column">
              <div>
                <h3>Cardiologia</h3>
              </div>
              <div *ngFor="let element of utente.listino_personale; let i=index">
                <mat-form-field fxFlexFill *ngIf="element.strutture_abilitate==2">
                  <input placeholder="{{utente.listino_personale[i].descrizione}}" matInput [(ngModel)]="utente.listino_personale[i].costo" name="{{element.descrizione}}">
                  <mat-icon matSuffix >€</mat-icon>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div fxFlex="33%">
            <div fxLayout="column">
              <div>
                <h3>Pneumologia</h3>
              </div>
              <div *ngFor="let element of utente.listino_personale; let i=index">
                <mat-form-field fxFlexFill *ngIf="element.strutture_abilitate==4">
                  <input placeholder="{{utente.listino_personale[i].descrizione}}" matInput [(ngModel)]="utente.listino_personale[i].costo" name="{{element.descrizione}}">
                  <mat-icon matSuffix >€</mat-icon>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div fxFlex="33%">
            <div fxLayout="column">
              <div>
                <h3>Fisiatria</h3>
              </div>
              <div *ngFor="let element of utente.listino_personale; let i=index">
                <mat-form-field fxFlexFill *ngIf="element.strutture_abilitate==1">
                  <input placeholder="{{utente.listino_personale[i].descrizione}}" matInput [(ngModel)]="utente.listino_personale[i].costo" name="{{element.descrizione}}">
                  <mat-icon matSuffix >€</mat-icon>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
        <!--<div flexLayout="row" fxLayout.xs="column">
          <div fxFlex="100%">
            <h3>Cardiologia</h3>
          </div>
        </div>
        <div flexLayout="row" fxLayout.xs="column">
          <div fxFlex="20%">
            <mat-form-field fxFlexFill>
              <input placeholder="Visita ed ECG" matInput [(ngModel)]="utente.listino_personale.visita_ecg" name="utente.listino_personale.visita_ecg">
              <mat-icon matSuffix >€</mat-icon>
            </mat-form-field>
          </div>
          <div fxFlex="20%">
            <mat-form-field fxFlexFill>
              <input placeholder="Controllo" matInput [(ngModel)]="utente.listino_personale.controllo" name="utente.listino_personale.controllo">
              <mat-icon matSuffix >€</mat-icon>
            </mat-form-field>
          </div>
          <div fxFlex="20%">
            <mat-form-field fxFlexFill>
              <input placeholder="Controllo con ECG" matInput [(ngModel)]="utente.listino_personale.controllo_ecg" name="utente.listino_personale.controllo_ecg">
              <mat-icon matSuffix >€</mat-icon>
            </mat-form-field>
          </div>
          <div fxFlex="20%">
            <mat-form-field fxFlexFill>
              <input placeholder="ECG" matInput [(ngModel)]="utente.listino_personale.ecg" name="utente.listino_personale.ecg">
              <mat-icon matSuffix >€</mat-icon>
            </mat-form-field>
          </div>
          <div fxFlex="20%">
            <mat-form-field fxFlexFill>
              <input placeholder="Visita Priority" matInput [(ngModel)]="utente.listino_personale.visita_priority" name="utente.listino_personale.visita_priority">
              <mat-icon matSuffix >€</mat-icon>
            </mat-form-field>
          </div>
        </div>
        <div flexLayout="row" fxLayout.xs="column">
          <div fxFlex="20%">
            <mat-form-field fxFlexFill>
              <input placeholder="Prima Visita Priority" matInput [(ngModel)]="utente.listino_personale.prima_visita_priority" name="utente.listino_personale.prima_visita_priority">
              <mat-icon matSuffix >€</mat-icon>
            </mat-form-field>
          </div>
          <div fxFlex="20%">
            <mat-form-field fxFlexFill>
              <input placeholder="Ecocardiogramma" matInput [(ngModel)]="utente.listino_personale.ecocardiogramma" name="utente.listino_personale.ecocardiogramma">
              <mat-icon matSuffix >€</mat-icon>
            </mat-form-field>
          </div>
          <div fxFlex="20%">
            <mat-form-field fxFlexFill>
              <input placeholder="TSA" matInput [(ngModel)]="utente.listino_personale.tsa" name="utente.listino_personale.tsa">
              <mat-icon matSuffix >€</mat-icon>
            </mat-form-field>
          </div>
          <div fxFlex="20%">
            <mat-form-field fxFlexFill>
              <input placeholder="Doppler" matInput [(ngModel)]="utente.listino_personale.doppler" name="utente.listino_personale.doppler">
              <mat-icon matSuffix >€</mat-icon>
            </mat-form-field>
          </div>
          <div fxFlex="20%">
            <mat-form-field fxFlexFill>
              <input placeholder="Test da Sforzo" matInput [(ngModel)]="utente.listino_personale.test_sforzo" name="utente.listino_personale.test_sforzo">
              <mat-icon matSuffix >€</mat-icon>
            </mat-form-field>
          </div>
        </div>
        <div flexLayout="row" fxLayout.xs="column">
          <div fxFlex="100%">
            <h3>Pneumologia</h3>
          </div>
        </div>
        <div flexLayout="row" fxLayout.xs="column">
          <div fxFlex="20%">
            <mat-form-field fxFlexFill>
              <input placeholder="Visita" matInput [(ngModel)]="utente.listino_personale.visita_p" name="utente.listino_personale.visita_p">
              <mat-icon matSuffix >€</mat-icon>
            </mat-form-field>
          </div>
          <div fxFlex="20%">
            <mat-form-field fxFlexFill>
              <input placeholder="Controllo" matInput [(ngModel)]="utente.listino_personale.controllo_p" name="utente.listino_personale.controllo_p">
              <mat-icon matSuffix >€</mat-icon>
            </mat-form-field>
          </div>
          <div fxFlex="20%">
            <mat-form-field fxFlexFill>
              <input placeholder="Visita Privata" matInput [(ngModel)]="utente.listino_personale.visita_privata" name="utente.listino_personale.visita_privata">
              <mat-icon matSuffix >€</mat-icon>
            </mat-form-field>
          </div>
          <div fxFlex="20%">
            <mat-form-field fxFlexFill>
              <input placeholder="Spirometria Semplice" matInput [(ngModel)]="utente.listino_personale.spiro_semplice" name="utente.listino_personale.spiro_semplice">
              <mat-icon matSuffix >€</mat-icon>
            </mat-form-field>
          </div>
          <div fxFlex="20%">
            <mat-form-field fxFlexFill>
              <input placeholder="Spirometri Globale" matInput [(ngModel)]="utente.listino_personale.spiro_globale" name="utente.listino_personale.spiro_globale">
              <mat-icon matSuffix >€</mat-icon>
            </mat-form-field>
          </div>
        </div>
        <div flexLayout="row" fxLayout.xs="column">
          <div fxFlex="20%">
            <mat-form-field fxFlexFill>
              <input placeholder="Test di Broncodilatazione" matInput [(ngModel)]="utente.listino_personale.spiro_basale" name="utente.listino_personale.spiro_basale">
              <mat-icon matSuffix >€</mat-icon>
            </mat-form-field>
          </div>
          <div fxFlex="20%">
            <mat-form-field fxFlexFill>
              <input placeholder="Spirometria Completa" matInput [(ngModel)]="utente.listino_personale.spiro_completa" name="utente.listino_personale.spiro_completa">
              <mat-icon matSuffix >€</mat-icon>
            </mat-form-field>
          </div>
          <div fxFlex="20%">
            <mat-form-field fxFlexFill>
              <input placeholder="Visita e Semplice" matInput [(ngModel)]="utente.listino_personale.visita_spiro_semplice" name="utente.listino_personale.visita_spiro_semplice">
              <mat-icon matSuffix >€</mat-icon>
            </mat-form-field>
          </div>
          <div fxFlex="20%">
            <mat-form-field fxFlexFill>
              <input placeholder="Visita e Globale" matInput [(ngModel)]="utente.listino_personale.visita_spiro_globale" name="utente.listino_personale.visita_spiro_globale">
              <mat-icon matSuffix >€</mat-icon>
            </mat-form-field>
          </div>
          <div fxFlex="20%">
            <mat-form-field fxFlexFill>
              <input placeholder="Visita e Basale" matInput [(ngModel)]="utente.listino_personale.visita_spiro_basale" name="utente.listino_personale.visita_spiro_basale">
              <mat-icon matSuffix >€</mat-icon>
            </mat-form-field>
          </div>
        </div>
        <div flexLayout="row" fxLayout.xs="column">
          <div fxFlex="20%">
            <mat-form-field fxFlexFill>
              <input placeholder="Visita e Completa" matInput [(ngModel)]="utente.listino_personale.visita_spiro_completa" name="utente.listino_personale.visita_spiro_completa">
              <mat-icon matSuffix >€</mat-icon>
            </mat-form-field>
          </div>
          <div fxFlex="20%">
            <mat-form-field fxFlexFill>
              <input placeholder="Controllo e Semplice" matInput [(ngModel)]="utente.listino_personale.controllo_spiro_semplice" name="utente.listino_personale.controllo_spiro_semplice">
              <mat-icon matSuffix >€</mat-icon>
            </mat-form-field>
          </div>
          <div fxFlex="20%">
            <mat-form-field fxFlexFill>
              <input placeholder="Controllo e Globale" matInput [(ngModel)]="utente.listino_personale.controllo_spiro_globale" name="utente.listino_personale.controllo_spiro_globale">
              <mat-icon matSuffix >€</mat-icon>
            </mat-form-field>
          </div>
          <div fxFlex="20%">
            <mat-form-field fxFlexFill>
              <input placeholder="Controllo e Basale" matInput [(ngModel)]="utente.listino_personale.controllo_spiro_basale" name="utente.listino_personale.controllo_spiro_basale">
              <mat-icon matSuffix >€</mat-icon>
            </mat-form-field>
          </div>
          <div fxFlex="20%">
            <mat-form-field fxFlexFill>
              <input placeholder="Controllo e Completa" matInput [(ngModel)]="utente.listino_personale.controllo_spiro_completa" name="utente.listino_personale.controllo_spiro_completa">
              <mat-icon matSuffix >€</mat-icon>
            </mat-form-field>
          </div>
        </div>
        <div flexLayout="row" fxLayout.xs="column">
          <div fxFlex="20%">
            <mat-form-field fxFlexFill>
              <input placeholder="Test del Cammino" matInput [(ngModel)]="utente.listino_personale.test_cammino" name="utente.listino_personale.test_cammino">
              <mat-icon matSuffix >€</mat-icon>
            </mat-form-field>
          </div>
          <div fxFlex="20%">
            <mat-form-field fxFlexFill>
              <input placeholder="Polisonnografia" matInput [(ngModel)]="utente.listino_personale.polisonnografia" name="utente.listino_personale.polisonnografia">
              <mat-icon matSuffix >€</mat-icon>
            </mat-form-field>
          </div>
          <div fxFlex="20%">
            <mat-form-field fxFlexFill>
              <input placeholder="Prick Test" matInput [(ngModel)]="utente.listino_personale.prick_test" name="utente.listino_personale.prick_test">
              <mat-icon matSuffix >€</mat-icon>
            </mat-form-field>
          </div>
          <div fxFlex="20%">
            <mat-form-field fxFlexFill>
              <input placeholder="Prescrizione Fisioterapia" matInput [(ngModel)]="utente.listino_personale.prpn" name="utente.listino_personale.prpn">
              <mat-icon matSuffix >€</mat-icon>
            </mat-form-field>
          </div>
          <div fxFlex="20%">
          </div>
        </div>
        <div flexLayout="row" fxLayout.xs="column">
          <div fxFlex="100%">
            <h3>Fisiatria</h3>
          </div>
        </div>
        <div flexLayout="row" fxLayout.xs="column">
          <div fxFlex="20%">
            <mat-form-field fxFlexFill>
              <input placeholder="P.R.I." matInput [(ngModel)]="utente.listino_personale.pri" name="utente.listino_personale.pri">
              <mat-icon matSuffix >€</mat-icon>
            </mat-form-field>
          </div>
          <div fxFlex="20%">
            <mat-form-field fxFlexFill>
              <input placeholder="Baropodometria/Biometria" matInput [(ngModel)]="utente.listino_personale.bpe" name="utente.listino_personale.bpe">
              <mat-icon matSuffix >€</mat-icon>
            </mat-form-field>
          </div>
          <div fxFlex="20%">
            <mat-form-field fxFlexFill>
              <input placeholder="Esito Inail" matInput [(ngModel)]="utente.listino_personale.esito_inail" name="utente.listino_personale.esito_inail">
              <mat-icon matSuffix >€</mat-icon>
            </mat-form-field>
          </div>
          </div>-->
    </form>
    
  </mat-card-content>
  <a mat-fab color="primary" (click)="SaveForm()" class="bottom-right" [disabled]="!UtenteForm.valid"> 
        <mat-icon>save</mat-icon>
  </a>
</mat-card>
