<mat-sidenav-container>

  <!-- Menù laterale -->
  <mat-sidenav mode="side" [opened]='true'>
    <mat-toolbar>
      <span>Utenti</span>
    </mat-toolbar>
    <mat-nav-list>
      <a mat-list-item [routerLink]="['/amministrazione-utenti']"> <mat-icon>recent_actors</mat-icon>&nbsp;Elenco Utenti </a>
      <a mat-list-item [routerLink]="['/amministrazione-utenti/utente','']"> <mat-icon>add_circle_outline</mat-icon>&nbsp;Nuovo Utente </a>
   </mat-nav-list>
  </mat-sidenav>

  <!-- Main content con route outlet -->
  <mat-sidenav-content><router-outlet></router-outlet></mat-sidenav-content>

</mat-sidenav-container>
