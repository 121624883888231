export class Polisonno{
    id;
    id_paziente;
    nome;
    cognome;
    data_nascita;
    cognome_nome;
    telefono;
    via;
    citta;
    data_richiesta;
    data_esecuzione;
    data_refertazione;
    data_consegna;
    data_fattura;
    annullato;
    note;
}