import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {JwtHelperService} from '@auth0/angular-jwt';

import { environment } from '../../environments/environment';
import { Utenti } from '../_models';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    public token: string;
    jwtHelper: JwtHelperService = new JwtHelperService();
    private currentUserSubject: BehaviorSubject<Utenti>;
    public currentUser: Observable<Utenti>;

    constructor(private http: HttpClient) {
        this.currentUserSubject = new BehaviorSubject<Utenti>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): Utenti {
        return this.currentUserSubject.value;
    }

    login(username: string, password: string) {
        return this.http.post<any>(`https://studio.medicina2000bari.it/APIv2/login/`, JSON.stringify({ username, password }))
            .pipe(map(response => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                //localStorage.setItem('currentUser', JSON.stringify(user));
                //this.currentUserSubject.next(user);
                if(response.chgpsw) return 0;
                let token = response.data && response.data.jwt;
                if (token) {
                    this.token = token;

                    // decodifico il token di risposta e lo memorizzo in local storage insieme ai dati che voglio usare frequentemente
                    sessionStorage.setItem('token', token);
                    sessionStorage.setItem('username', this.jwtHelper.decodeToken(token).userdata['username'].toUpperCase());
                    localStorage.setItem('currentUser', JSON.stringify(this.jwtHelper.decodeToken(token).userdata));
                    this.currentUserSubject.next(this.jwtHelper.decodeToken(token).userdata);
                    
                    // return true, il login è OK
                    return 1;
                }
                else return 2
            }))
    }

    chgpsw(username: string, password: string, new_password:string) {
        return this.http.post<any>(`https://studio.medicina2000bari.it/APIv2/login/chgpsw/`, JSON.stringify({ username, password,new_password }))
            .pipe(map(response => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                //localStorage.setItem('currentUser', JSON.stringify(user));
                //this.currentUserSubject.next(user);
               if(response.data=='update')
                    return true;
                else
                    return false;
                }
            ))
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('username');
        this.currentUserSubject.next(null);
    }
}