export * from './utenti';
export * from './presenze';
export * from './pazienti';
export * from './schede';
export * from './note';
export * from './visite';
export * from './impostazioni';
export * from './holter';
export * from './plantari';
export * from './polisonno';

export class Privato{
    paziente;
    data;
    prestazione;
    username;
  }
  
  export class Segnalazioni{
    segnalazione;
  }

  export interface DialogData {
    data;
    id;
  }
  