<mat-card>
    <mat-card-title *ngIf="!dataRef">Elenco Pazienti <button *ngIf="access_level<3 || access_level==6"  color="primary" mat-flat-button color="primary" (click)="routing('archivio/paziente','')">Nuovo Paziente</button> <button [routerLink]="['/scheda','']" mat-flat-button color="primary">Nuova Scheda</button></mat-card-title>
    <mat-card-content>
      <div fxLayout="row" fx-layout-align="start center" class="pad-left-sm pad-right-sm">
        <div fxFlex="100%">
            <mat-form-field appearance="standard" *ngIf="!dataRef">
                <mat-label>Filtra i pazienti per Cognome e Nome (scrivere cognome e nome senza spazio tra l'uno e l'altro)</mat-label>
                <input matInput (keyup)="applyFilter('cognome',$event)" placeholder="Scrivi qui il cognome..." #input>
              </mat-form-field>
              
              <table mat-table [dataSource]="dataSource" multiTemplateDataRows class="mat-elevation-z8" matSort>
              
                <!-- ID Column -->
                <ng-container matColumnDef="id">
                  <th mat-header-cell *matHeaderCellDef> ID </th>
                  <td mat-cell *matCellDef="let element"> {{element.idscheda}} </td>
                </ng-container>
              
                <!-- Cognome Column -->
                <ng-container matColumnDef="cognome">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Cognome </th>
                  <td mat-cell *matCellDef="let element"> {{element.cognome}} </td>
                </ng-container>
              
                <!-- Nome Column -->
                <ng-container matColumnDef="nome">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Nome </th>
                  <td mat-cell *matCellDef="let element"> {{element.nome}} </td>
                </ng-container>
              
                <!-- Data di Nascita Column -->
                <ng-container matColumnDef="data">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Data di Nascita </th>
                  <td mat-cell *matCellDef="let element"> {{element.data_nascita | date:'dd/MM/yyyy'}} </td>
                </ng-container>

                <!-- Azioni 
                <ng-container matColumnDef="azioni">
                    <th mat-header-cell *matHeaderCellDef>  </th>
                    <td mat-cell *matCellDef="let element">
                        <button mat-icon-button [mat-menu-trigger-for]="menu">
                        <mat-icon>more_vert</mat-icon>
                        </button>
                        <mat-menu x-position="before" #menu="matMenu">
                            <button (click)="routing('archivio/paziente', element.idscheda)" mat-menu-item><mat-icon mat-list-icon>edit</mat-icon>Modifica Paziente</button>
                            <button *ngIf="access_level==1" (click)="DisattivaPaziente(element.idscheda)" mat-menu-item><mat-icon mat-list-icon>delete</mat-icon>Elimina Paziente</button>
                            <button *ngIf="access_level==1 || access_level==7 || access_level==8" (click)="routing_visite('archivio/visite',element.idscheda,0)" mat-menu-item><mat-icon mat-list-icon>add</mat-icon>Nuova Visita Cardiologica</button>
                            <button *ngIf="access_level==1 || access_level==10" (click)="routing_visite('archivio/visite',element.idscheda,1)" mat-menu-item><mat-icon mat-list-icon>add</mat-icon>Nuova Visita Pneumologica</button>
                            <button *ngIf="access_level==1 || access_level==9" (click)="routing_visite('archivio/visite',element.idscheda,2)" mat-menu-item><mat-icon mat-list-icon>add</mat-icon>Nuova Visita Fisitarica</button>
                        </mat-menu>
                    </td>
                  </ng-container>
                  -->


<!-- Dettagli -->
                  <ng-container matColumnDef="expandedDetail">
                    <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
                      <div class="example-element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                        <mat-card class="full-width details">
                          <mat-card-content>
                            <div fxLayout="row">
                              <div fxFlex="100%">
                                <button mat-flat-button color="accent" *ngIf="access_level==1 || access_level==2 || access_level==6" (click)="routing('archivio/paziente', element.idscheda)" ><mat-icon mat-list-icon>edit</mat-icon>Modifica Paziente</button>
                                <button mat-flat-button color="accent" *ngIf="access_level==1" (click)="DisattivaPaziente(element.idscheda)" ><mat-icon mat-list-icon>delete</mat-icon>Elimina Paziente</button>
                                <button mat-flat-button color="accent" *ngIf="access_level==1" (click)="routing_visite('archivio/visite',element.idscheda,0)"><mat-icon mat-list-icon>add</mat-icon>Nuova Visita Cardiologica</button>
                                <button mat-flat-button color="accent" *ngIf="access_level==1" (click)="routing_visite('archivio/visite',element.idscheda,1)" ><mat-icon mat-list-icon>add</mat-icon>Nuova Visita Pneumologica</button>
                                <button mat-flat-button color="accent" *ngIf="access_level==1" (click)="routing_visite('archivio/visite',element.idscheda,2)" ><mat-icon mat-list-icon>add</mat-icon>Nuova Visita Fisitarica</button>
                                <button mat-flat-button color="accent" *ngIf="access_level==1 || access_level==2" (click)="routing('/scheda/nsp',element.idscheda)"><mat-icon mat-list-icon>add</mat-icon>Nuova Scheda</button>
                              </div>
                            </div>
                          <div fxLayout="row" fxLayout.xs="column">
                            <!-- VISITE -->
                            <div fxFlex="40%" fxFlex.xs="100%" *ngIf="access_level<3 || access_level>5">
                              <mat-card>
                                <mat-card-title>
                                  Visite Effettuate
                                </mat-card-title>
                                <mat-card-content>
                                  <div *ngFor="let visita of visite">
                                    <b>{{visita.data | date: 'dd-MM-yyyy'}}: {{visita.descrizione}} </b>- {{visita.branca}} - {{visita.operatore}} <br/><button mat-button color="primary" (click)="showInfo(visita,0)" ><mat-icon>search</mat-icon> Visualizza</button><button mat-button color="primary" (click)="showInfo(visita,1)" ><mat-icon>print</mat-icon> Stampa</button><button mat-button color="primary" (click)="showInfo(visita,2)" ><mat-icon>save</mat-icon> Scarica</button>
                                  </div>
                                </mat-card-content>
                              </mat-card>
                            </div>
                            <!--VISITE-->
                            <!--SCHEDE-->
                            <div fxFlex="40%" fxFlex="100%" *ngIf="access_level<4">
                              <mat-card>
                                <mat-card-title>
                                  SCHEDE - <a class="cs" (click)="StampaCertificato(element,'','Fisiatria')">Stampa attestato</a>
                                </mat-card-title>
                                <mat-card-content *ngIf="dati_schede.length">
                                  <div fxLayout="row" *ngFor="let dati_scheda of dati_schede">
                                    <div fxFlex="100%">
                                        <div fxLayout="row" class="box">
                                            <span class="green-dot" *ngIf="dati_scheda.attivo==1"></span><span class="red-dot" *ngIf="dati_scheda.attivo==0"></span>&nbsp; Scheda n. {{dati_scheda.id_scheda}} - <a *ngIf="access_level<3" [routerLink]="['/scheda',dati_scheda.id_scheda]">Modifica</a>&nbsp;<button  color="primary" mat-raised-button *ngIf="access_level<3 && dati_scheda.attivo==1" (click)="Archivia(dati_scheda.id_scheda)">Archivia</button>&nbsp;<button  color="primary" mat-raised-button *ngIf="access_level<3 && dati_scheda.attivo==0" (click)="Archivia(dati_scheda.id_scheda)">Ripristina</button>
                                        </div>
                                        <div fxLayout="row" class="box">
                                            <div fxFlex="20%" class="title">
                                                Diagnosi
                                            </div>
                                            <div fxFlex="80%"> {{dati_scheda.diagnosi}}</div>
                                        </div>
                                        <div fxLayout="row" class="box">
                                          <div fxFlex="20%" class="title">
                                              Note Operative per i terapisti
                                          </div>
                                          <div fxFlex="80%"> {{dati_scheda.note_operative}}</div>
                                      </div>
                                        <div fxLayout="row" class="box" *ngIf="access_level<=2">
                                            <div fxFlex="20%" class="title">
                                                Note
                                            </div>
                                            <div fxFlex="80%"> {{dati_scheda.note}}</div>
                                        </div>
                                        <div fxLayout="row" class="box" *ngFor="let terapia of dati_scheda.terapie">
                                            <div fxFlex="40%">
                                                <div class="titolo-terapia">{{terapia.terapia}}</div>
                                                Segmento: {{terapia.segmento}}<br/>
                                                Quantità prescritta: {{terapia.quantita_prescritta}}<br/>
                                                Quantità eseguite: {{terapia.quantita_eseguite}}<br/>
                                                Quantità abilitate: {{terapia.quantita_da_eseguire}}<br/>
                                                <form *ngIf="access_level<=2" class="box_operazione"><mat-form-field style="width: 50%">
                                                    <mat-select [(ngModel)]="quantita_abilitata" name="quantita_abilitata" >
                                                        <mat-option *ngFor="let option of option_select" [value]="option">{{option}}</mat-option>
                                                    </mat-select><br/></mat-form-field>
                                                    <a (click)="Abilita_terapia(terapia.id_segmento,terapia.id_terapia,dati_scheda.id_scheda)" mat-button>Abilita</a>
                                                </form> 
                                                <form *ngIf="access_level>2 && terapia.quantita_da_eseguire>0 && check_data(dati_scheda,terapia)" class="box_operazione"><mat-form-field style="width: 50%">
                                                    <mat-select [(ngModel)]="box_selected" name="box_selected" >
                                                        <mat-option value="P">P</mat-option>
                                                        <mat-option value="1">1</mat-option>
                                                        <mat-option value="2">2</mat-option>
                                                        <mat-option value="3">3</mat-option>
                                                        <mat-option value="4">4</mat-option>
                                                        <mat-option value="5">5</mat-option>
                                                        <mat-option value="6">6</mat-option>
                                                        <mat-option value="7">7</mat-option>
                                                        <mat-option value="8">8</mat-option>
                                                        <mat-option value="9">9</mat-option>
                                                        <mat-option value="10">10</mat-option>
                                                    </mat-select><br/></mat-form-field>
                                                    <a (click)="Esegui_terapia(terapia.id_segmento,terapia.id_terapia,dati_scheda.id_scheda)" mat-button>Esegui</a>
                                                </form>
                                                <form *ngIf="access_level==1 && terapia.quantita_da_eseguire>0" class="box_operazione">
                                                <mat-form-field style="width: 50%">
                                                    <mat-select [(ngModel)]="box_selected" name="box_selected" >
                                                        <mat-option value="P">P</mat-option>
                                                        <mat-option value="1">1</mat-option>
                                                        <mat-option value="2">2</mat-option>
                                                        <mat-option value="3">3</mat-option>
                                                        <mat-option value="4">4</mat-option>
                                                        <mat-option value="5">5</mat-option>
                                                        <mat-option value="6">6</mat-option>
                                                        <mat-option value="7">7</mat-option>
                                                        <mat-option value="8">8</mat-option>
                                                        <mat-option value="9">9</mat-option>
                                                        <mat-option value="10">10</mat-option>
                                                    </mat-select>
                                                </mat-form-field><br/>
                                                <mat-form-field style="width: 50%">
                                                    <mat-select [(ngModel)]="username_forzato" name="username_forzato" >
                                                        <mat-option *ngFor="let utente of utenti" value='{{utente.username}}'>{{utente.username}}</mat-option>
                                                    </mat-select>
                                                </mat-form-field><br/>
                                                <mat-form-field style="width: 50%">
                                                        <input matInput placeholder="aaaa-mm-dd hh:mm" [(ngModel)]="timestamp_forzato" name="timestamp_forzato">
                                                </mat-form-field><br/>
                                                
                                                    <a (click)="Esegui_terapia_forzata(terapia.id_segmento,terapia.id_terapia,dati_scheda.id_scheda)" mat-button>Esecuzione forzata</a>
                                                </form>
                                            </div>
                                            
                                            <div fxFlex="60%" style="height: 175px; overflow: auto">
                                              <div *ngFor="let row of terapia.eseguite; let index= index">
                                                <mat-list-item>
                                                    <div fxLayout="row">
                                                        <div fxFlex="45%" class="bold">{{index+1}} - {{row.timestamp | date: 'dd/MM/yyyy - HH:mm'}}</div>
                                                        <div fxFlex="45%">{{row.username}} - Box: {{row.box}}</div>
                                                        <div fxFlex="10%" *ngIf="access_level<=2"><a (click)="Rimuovi_operazione(terapia.id_segmento,terapia.id_terapia,dati_scheda.id_scheda,row.timestamp)"><mat-icon color="warn">cancel</mat-icon></a></div>
                                                    </div>
                                                </mat-list-item>
                                              </div>
                                            </div>
                                        </div>
                                    </div>
                                    <mat-divider></mat-divider>
                                </div>
                                </mat-card-content>
                              </mat-card>
                            </div>
                            <!--SCHEDE-->
                          </div>
                        </mat-card-content>
                        </mat-card>
                      </div>
                    </td>
                  </ng-container>
              
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

                <tr mat-row *matRowDef="let element; columns: displayedColumns;"
                    class="example-element-row"
                    [class.example-expanded-row]="expandedElement === element"
                    (click)="MostraVisite(element)">
                </tr>

                <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
              
                <!-- Row shown when there is no matching data. -->
                <tr class="mat-row" *matNoDataRow>
                  <td class="mat-cell" colspan="4">Nessun risultato trovato</td>
                </tr>

              </table>
              <mat-paginator [pageSizeOptions]="[10, 20, 50, 100]" aria-label="Select page of users"></mat-paginator>
         
        </div>
      </div>
      <button mat-raised-button *ngIf="dataRef" color="primary" (click)="dialogRef.close()">Chiudi</button>
    </mat-card-content>
  </mat-card>
