export class Utenti{
    idutente: number;
    username: string;
    password: string;
    ruolo: number;
    tipo_utente: string;
    nominativo: number;
    data_in: Date;
    data_out: Date;
    rivalsa;
    ritenuta;
    email;
    percentuale;
    listino_personale;
    remunerazione_oraria;
}

export class Tipo{
    typeid: number;
    tipo_utente: string;
}

export class Listino{
    visita_ecg;
    controllo;
    controllo_ecg;
    ecg;
    visita_priority;
    prima_visita_priority;
    ecocardiogramma;
    tsa;
    doppler;
    test_sforzo;
    visita_p;
    controllo_p;
    visita_privata;
    spiro_semplice;
    spiro_globale;
    spiro_basale;
    spiro_completa;
    visita_spiro_semplice;
    visita_spiro_globale;
    visita_spiro_basale;
    visita_spiro_completa;
    controllo_spiro_semplice;
    controllo_spiro_globale;
    controllo_spiro_basale;
    controllo_spiro_completa;
    test_cammino;
    polisonnografia;
    prick_test;
    prpn;
    pri;
    bpe;
    esito_inail;
  }
  